import { transparentize } from 'polished';
import React from 'react';
import Autosuggest from 'react-autosuggest';
import styled from 'styled-components';

const AutoWrapper = styled.div`
  max-width: 100%;
  position: relative;

  & i {
    color: ${(props) => props.theme.PrimaryBorderColor};
    font-size: ${(props) => props.theme.FontSize_s};
    font-style: normal;
    position: absolute;
    right: 10px;
    top: 15px;
    z-index: 2;
  }

  & .react-autosuggest__container {
    & input {
      border-radius: ${(props) => props.theme.PrimaryRadius};
      border: 1px solid ${(props) => props.theme.PrimaryColor};
      color: ${(props) => props.theme.PrimaryFontColor};
      font-size: ${(props) => props.theme.FontSize_s};
      padding: 13px 15px;
      width: 100%;

      &:focus {
        outline: none;
      }
    }

    &
      .react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open {
      background: ${(props) => props.theme.PrimaryWhite};
      border: 1px solid ${(props) => props.theme.PrimaryDark};
      border-radius: 0 0 ${(props) => props.theme.PrimaryRadius}
        ${(props) => props.theme.PrimaryRadius};
      margin-top: -2px;
      position: absolute;
      width: 100%;
      z-index: 900;

      & ul.react-autosuggest__suggestions-list {
        list-style: none;
        margin: 0;
        padding: 0;

        & li {
          border-bottom: 1px solid
            ${(props) => transparentize(0.7, props.theme.PrimaryDark)};
          color: ${(props) => transparentize(0.3, props.theme.PrimaryFontColor)};
          padding: 15px;
          text-transform: capitalize;

          &:hover {
            background: ${(props) => transparentize(0.9, props.theme.PrimaryDark)};
          }

          &:last-child {
            border-bottom: 0;
          }

          & span {
            display: block;
            font-size: ${(props) => props.theme.FontSize_s};
            opacity: 0.6;
            padding-left: 35px;
          }
        }
      }
    }
  }
`;

export const AutoSuggest = (props) => (
  <AutoWrapper>
    <Autosuggest {...props} />
    {props.statusIcon}
  </AutoWrapper>
);
