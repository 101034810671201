import React from 'react';

import { helpers } from '../../../utils';

const LegendList = ({ name, dataLayers }) => {
  return (
    <div className="legend-list">
      <p>Legend {name}</p>

      <div className="legend-span">
        {dataLayers.map((dataLayer, i) => (
          <span className="data-layer-span" key={i}>
            <i
              style={
                dataLayer.icon
                  ? { color: dataLayer.layerStyle.styles.icon.color }
                  : { ...helpers.getLegendStyle(dataLayer) }
              }
              className={dataLayer.icon ? `mp-${dataLayer.icon}` : 'legend_key'}
            />

            {dataLayer.name}
          </span>
        ))}
      </div>
    </div>
  );
};

export default LegendList;
