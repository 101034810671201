import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FieldActions, setTableField } from '../../redux/actions/mapDocument';
import { DataLayer, DataTable, DefaultRootState } from '../../types';
import { helpers } from '../../utils';
import { Boxed, H5, Theme } from '../styled-components';
import ColumnDataList from './ColumnDataList';
import ColumnDataPane from './ColumnDataPane';

const TableSidebar = ({ editField }) => {
  const {
    dataLayer: { dataLayerFeatures },
    mapDocument: {
      current: {
        mapTemplate: { dataLayers },
        settlementFields,
      },
    },
  } = useSelector((state: DefaultRootState) => state);
  const dispath = useDispatch();
  const selectedDataLayers = dataLayers.filter(
    (dataLayer: DataLayer) =>
      dataLayerFeatures[dataLayer.id] && dataLayerFeatures[dataLayer.id].checked,
  );
  const layerCategories = helpers.groupLayersByCategory(selectedDataLayers);
  const dataTables = helpers.getDataTables(
    dataLayerFeatures,
    layerCategories,
    settlementFields,
  );

  const onAddField = (field) => {
    dispath(setTableField(field, FieldActions.REPLACE));
  };

  return (
    <Boxed outline className="side-bar right">
      <Boxed
        pad="1.4rem 2rem"
        background={Theme.PrimaryBackground}
        outline
        className="side-bar-header"
      >
        <H5>Table Data </H5>
      </Boxed>

      {dataTables
        .filter(({ display }) => display)
        .map(({ fields }: DataTable, i) => (
          <div key={i}>
            <ColumnDataList fields={fields} editField={editField} />
            <ColumnDataPane onAddField={onAddField} fields={fields} />
          </div>
        ))}
    </Boxed>
  );
};

export default TableSidebar;
