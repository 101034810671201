import React from 'react';

import { Boxed, Grid, H5, Label, P, SimpleCheckbox, Theme } from '../styled-components';

const SideDrawer = ({
  dataLayers,
  dataLayersContents,
  description,
  editable,
  loadingFeatures,
  name,
  onToggleDataLayer,
  onToggleLayerLabel,
}) => {
  return (
    <Boxed outline pad="1.4rem 2rem" className="dropdown-container">
      <H5 color={Theme.PrimaryColor}>{name}</H5>
      <P>{description}</P>

      <Grid default="1fr 1fr" padall="5px 0">
        <Boxed pad="5px 0" borderBottom={Theme.PrimaryGreyLight}>
          <Label>Select Layers</Label>
        </Boxed>

        <Boxed pad="5px 0" borderBottom={Theme.PrimaryGreyLight} align="right">
          <Label>Show label</Label>
        </Boxed>
      </Grid>

      {dataLayers.map(({ id, name: layerName }) => {
        const layerContent = dataLayersContents[id] || {};
        const featureCount = (layerContent.features || []).length;
        const enabled = featureCount !== 0;
        const { checked = false, showLabel } = layerContent;

        const countStyle = { fontSize: Theme.FontSize_xs, color: 'blue' };
        const countTag = !checked ? (
          <React.Fragment />
        ) : (
          <span>
            {' '}
            <small style={countStyle}> ({featureCount}) </small>
          </span>
        );

        return (
          <Boxed className="datalayer-toggle" key={layerName}>
            <SimpleCheckbox
              key={id}
              type="checkbox"
              icon="icon mp-ok"
              badge={countTag}
              label={layerName}
              checked={checked ? 'checked' : ''}
              disabled={loadingFeatures || !editable || !enabled}
              onChange={() => onToggleDataLayer(id)}
            />

            {enabled && (
              <SimpleCheckbox
                key="toggleLabel"
                type="switch"
                checked={showLabel ? 'checked' : ''}
                disabled={!checked}
                onChange={() => onToggleLayerLabel(id)}
              />
            )}
          </Boxed>
        );
      })}
    </Boxed>
  );
};

export default SideDrawer;
