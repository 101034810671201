import React from 'react';

import { DataPortalLink } from '../extras/DataPortalLink';
import { Boxed, H5, Theme } from '../styled-components';
import LayerPane from './LayerPane';

const ExplorerSidebar = () => {
  return (
    <Boxed outline className="side-bar left map-preview-link">
      <Boxed>
        <Boxed
          outline
          pad="1.4rem 2rem"
          background={Theme.PrimaryBackground}
          className="side-bar-header"
        >
          <H5>Data Layers</H5>
        </Boxed>

        <LayerPane />
      </Boxed>

      <DataPortalLink />
    </Boxed>
  );
};

export default ExplorerSidebar;
