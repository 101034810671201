import { XYCoord } from 'dnd-core';
import React, { useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';

import { FieldActions, setTableField } from '../../redux/actions/mapDocument';
import { Boxed, P, PaleButton } from '../styled-components';

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export interface FieldProps {
  id: any;
  text: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

const ColumnData = ({ field, editField, moveCard }) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const onRemoveField = (field) => {
    dispatch(setTableField({ ...field, selected: false }, FieldActions.REPLACE));
  };

  const [{ opacity }, drag] = useDrag(() => ({
    type: 'field',
    item: field,
    collect: (monitor: any) => ({
      opacity: monitor.isDragging() ? 0 : 1,
    }),
  }));
  const [, drop] = useDrop({
    accept: 'field',
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = field.index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset() as XYCoord;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <div className="data-column-box" ref={ref} style={{ opacity }}>
      <i className="mp-move-icon" />
      <P>{field.name}</P>

      {!field.default && (
        <Boxed className="drag-button-box">
          <PaleButton margin="0 0.5rem" onClick={() => editField(field)} small>
            Edit
          </PaleButton>

          <PaleButton onClick={() => onRemoveField(field)} small>
            Remove
          </PaleButton>
        </Boxed>
      )}
    </div>
  );
};

export default ColumnData;
