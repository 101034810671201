import { urls } from '../../utils';
import {
  GET_MAP_TEMPLATE_FAILURE,
  GET_MAP_TEMPLATE_REQUEST,
  GET_MAP_TEMPLATE_SUCCESS,
} from '../types';

export const getMapTemplate = () => (dispatch) => {
  dispatch({
    types: [
      GET_MAP_TEMPLATE_REQUEST,
      GET_MAP_TEMPLATE_SUCCESS,
      GET_MAP_TEMPLATE_FAILURE,
    ],
    promise: (client: any) => client.get(`${urls.MAP_TEMPLATE}/id`),
  });
};
