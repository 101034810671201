import 'react-day-picker/lib/style.css';

import { darken, lighten, transparentize } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import styled, { css } from 'styled-components';

import { Label } from './Typography';

const DatePickerWrapper = styled.div`
  font-family: ${(props) => props.theme.PrimaryFont};
  position: relative;

  &.calendar {
    display: inline-block;
    box-shadow: 0 1px 1px ${(props) => transparentize(0.9, props.theme.PrimaryDark)},
      0 3px 4px ${(props) => transparentize(0.9, props.theme.PrimaryGreyDark)};
  }

  .DayPicker {
    display: flex;
    justify-content: center;
  }

  .input-label {
    color: ${(props) => transparentize(0.3, props.theme.PrimaryFontColor)};
    font-size: ${(props) => props.theme.FontSize_xs};
    font-weight: 600;
    left: 2px;
    letter-spacing: 0.1em;
    position: absolute;
    top: -16px;
  }

  .DayPickerInput {
    width: 100%;
  }

  .DayPickerInput input {
    background: none;
    border: 1px solid ${(props) => lighten(0.6, props.theme.PrimaryFontColor)};
    border-radius: ${(props) => props.theme.PrimaryRadius};
    box-sizing: border-box;
    display: block;
    font-family: ${(props) => props.theme.PrimaryFont};
    font-size: ${(props) => props.theme.FontSize_standard};
    line-height: 22px;
    padding: 10px;
    position: relative;
    transition: ${(props) => props.theme.PrimaryTransition};
    width: 100%;
    z-index: 2;

    &:disabled {
      background: ${(props) => lighten(0.7, props.theme.PrimaryFontColor)};
      border: 1px solid ${(props) => lighten(0.67, props.theme.PrimaryFontColor)};
      color: ${(props) => lighten(0.5, props.theme.PrimaryFontColor)};
    }

    &:disabled::placeholder {
      color: ${(props) => lighten(0.5, props.theme.PrimaryFontColor)};
    }

    &:focus {
      border: 1px solid ${(props) => props.theme.PrimaryColor};
      box-shadow: none;
      outline: none;
    }

    &:focus:hover {
      border-width: 2px;
      box-shadow: none;
      padding: 9px;
    }
  }

  ${(props) =>
    props.icon &&
    css`
      .DayPickerInput {
        &::before {
          align-items: center;
          color: ${(props) => lighten(0.3, props.theme.PrimaryFontColor)};
          content: '\\e85b'; // mp-calendar-1
          display: flex;
          font-family: ${(props) => props.theme.IconFont};
          font-size: ${(props) => props.theme.FontSize_m};
          height: 44px;
          justify-content: center;
          position: absolute;
          right: 0;
          top: 0;
          width: 36px;
          z-index: 1;
        }
      }
    `}

  .DayPicker {
    font-size: ${(props) => props.theme.FontSize_standard};
  }

  .DayPicker-Weekday {
    color: ${(props) => props.theme.PrimaryGreyMid};
    font-size: ${(props) => props.theme.FontSize_xs};
    font-weight: 600;
    letter-spacing: 0.1em;
    padding-bottom: 0.8em;
    text-transform: uppercase;
  }

  .DayPicker-NavButton {
    background: none;
    color: ${(props) => props.theme.PrimaryGreyMid};
    outline: none;
    text-align: center;

    &::before {
      content: '\\e82c'; // mp-right-open
      display: block;
      font-family: ${(props) => props.theme.IconFont};
      font-size: ${(props) => props.theme.FontSize_l};
      line-height: 0.9em;
    }

    &:hover {
      color: ${(props) => props.theme.PrimaryColor};
    }
  }

  .DayPicker-NavButton--prev {
    &::before {
      content: '\\e82a'; // mp-left-open
      font-family: ${(props) => props.theme.IconFont};
    }
  }

  .DayPicker-Caption > div {
    font-size: ${(props) => props.theme.FontSize_standard};
    font-weight: 300;
  }

  .DayPicker-Day {
    color: ${(props) => props.theme.PrimaryFontColor};
    font-size: ${(props) => props.theme.FontSize_s};
    outline: none;
    padding: 0.7em;
  }

  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    &:hover {
      background: ${(props) => props.theme.PrimaryColor_light_60};
      background: red;
    }
  }

  .DayPicker-Day--outside {
    color: ${(props) => props.theme.PrimaryGreyMid};
  }

  .DayPicker-Day--disabled {
    color: ${(props) => transparentize(0.8, props.theme.PrimaryFontColor)};
  }

  .DayPicker-Day--today {
    color: ${(props) => props.theme.PrimaryColor};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background: ${(props) => props.theme.PrimaryColor};

    &:hover {
      background: ${(props) => props.theme.PrimaryColor_light_40};
    }
  }

  ${(props) =>
    props.horizontalLayout &&
    css`
      .DayPicker-Months {
        flex-wrap: nowrap;
      }

      .DayPickerInput-Overlay {
        width: auto;
      }
    `}

  .DayPicker-Footer {
    border-top: 1px solid
      ${(props) => transparentize(0.8, props.theme.PrimaryFontColor)};
    padding: 1em;
    padding-bottom: 0;
  }

  .DayPicker-TodayButton {
    background-color: ${(props) => props.theme.PrimaryColor};
    border: 0;
    border-radius: ${(props) => props.theme.PrimaryRadius};
    color: ${(props) => props.theme.PrimaryWhite};
    cursor: pointer;
    display: inline-block;
    font-size: ${(props) => props.theme.PrimaryFontSize};
    height: 44px;
    line-height: 20px;
    padding: 12px 22px;
    text-align: center;

    &:hover {
      background-color: ${(props) =>
        darken(0.1, props.color || props.theme.PrimaryColor)};
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  &.InputRange {
    display: flex;

    .DayPickerInput-Overlay {
      min-width: calc(200% + 10px);
    }

    .InputRange-to .DayPickerInput-Overlay {
      margin-left: calc((100% + 10px) * -1);
    }

    .InputRange-from,
    .InputRange-to {
      flex: 1;
      position: relative;
    }

    .InputRange-from .DayPickerInput {
      padding-right: 5px;
    }

    .InputRange-to .DayPickerInput {
      padding-left: 5px;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      background: ${(props) => transparentize(0.3, props.theme.PrimaryColor)};
      border: 1px solid ${(props) => props.theme.PrimaryWhite};
      border-radius: 0 !important;
    }

    .DayPicker-Day--end {
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }

    .DayPicker-Day--start {
      border-bottom-right-radius: 0 !important;
      border-top: 1px solid ${(props) => props.theme.PrimaryWhite};
      border-top-right-radius: 0 !important;
    }
  }
`;

export const DatePicker = (props) => {
  const handleDayClick = (day, { selected }) => {
    if (!selected) props.onChange(day);
  };

  return (
    <DatePickerWrapper {...props} className="datepicker">
      {props.label && <Label className="input-label">{props.label}</Label>}
      <DayPickerInput
        {...props}
        onDayChange={handleDayClick}
        dayPickerProps={props.calendarProps}
      />
    </DatePickerWrapper>
  );
};

DatePicker.propTypes = {
  calendarProps: PropTypes.object,
  horizontalLayout: PropTypes.bool,
  icon: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};
