import { darken, desaturate, lighten } from 'polished';
import styled, { css, keyframes } from 'styled-components';

const InProgress = keyframes`
  to {
    width: 0%;
  }
`;

export const Button = styled.button`
  background-color: ${(props) => props.color || props.theme.PrimaryColor};
  border: 0;
  border-radius: ${(props) => (props.rounded ? '22px' : props.theme.PrimaryRadius)};
  color: ${(props) => props.theme.PrimaryWhite};
  cursor: pointer;
  display: inline-block;
  font-family: ${(props) => props.theme.PrimaryFont};
  font-size: ${(props) => props.theme.PrimaryFontSize};
  line-height: 20px;
  margin: ${(props) => props.margin};
  padding: ${(props) => props.pad || '1em 1.6em'};
  text-align: center;
  transition: ${(props) => props.theme.PrimaryTransition};

  & svg,
  i {
    display: inline-block;
    margin: 0;
    padding: 0;
  }

  &:hover {
    background-color: ${(props) =>
      darken(0.1, props.color || props.theme.PrimaryColor)};
    color: ${(props) => lighten(0.4, props.color || props.theme.PrimaryWhite)};
  }

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  ${(props) =>
    props.light &&
    css`
      background-color: ${(props) =>
        props.color ? lighten(0.4, props.color) : props.theme.PrimaryColor_light_80};
      color: ${(props) =>
        props.color ? darken(0.2, props.color) : props.theme.PrimaryColor};

      &:hover {
        background-color: ${(props) =>
          props.color ? darken(0.2, props.color) : props.theme.PrimaryColor_light_40};
        color: ${(props) =>
          props.color ? lighten(0.4, props.color) : props.theme.PrimaryColor_light_80};
      }
    `}

  ${(props) =>
    props.iconed &&
    css`
      padding: 1em 1.6em;
      min-width: 44px;

      & i {
        font-size: ${(props) => props.theme.FontSize_l};
      }
    `}

  ${(props) =>
    props.mini &&
    css`
      height: 18px;
      min-width: auto;
      padding: 0;
      text-align: center;
      width: 18px;

      & i {
        font-size: ${(props) => props.theme.FontSize_m};
        line-height: 1em;
      }
    `}

  ${(props) =>
    props.iconLeft &&
    css`
      & i {
        margin-left: -6px;
        padding-right: 4px;
      }
    `}

  ${(props) =>
    props.iconRight &&
    css`
      & i {
        padding-left: 4px;
        margin-right: -6px;
      }
    `}

  ${(props) =>
    props.margin &&
    css`
      margin: ${(props) => props.margin};
    `}

  ${(props) =>
    props.width &&
    css`
      min-width: ${(props) => props.width};
    `}

  ${(props) =>
    props.small &&
    css`
      font-size: ${(props) => props.theme.FontSize_s};
      height: 30px;
      line-height: 16px;
      padding: 6px 12px;

      ${(props) =>
        props.iconed &&
        css`
          min-width: 30px;
          padding: 6px 6px;
        `}
    `}

  ${(props) =>
    props.large &&
    css`
      font-size: ${(props) => props.theme.FontSize_l};
      height: auto;
      line-height: 16px;
      padding: 15px 20px;

      ${(props) =>
        props.iconed &&
        css`
          padding: 15px 20px;
        `}
    `}

  ${(props) =>
    props.block &&
    css`
      display: block;
      width: 100%;
    `}

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${(props) =>
        desaturate(0.7, lighten(0.35, props.color || props.theme.PrimaryColor))};
      color: ${(props) =>
        desaturate(0.9, lighten(0.1, props.color || props.theme.PrimaryColor))};

      &:hover {
        background-color: ${(props) =>
          desaturate(0.7, lighten(0.35, props.color || props.theme.PrimaryColor))};
        color: ${(props) =>
          desaturate(0.9, lighten(0.1, props.color || props.theme.PrimaryColor))};
      }
    `}

  ${(props) =>
    props.progress &&
    css`
      background: none;
      overflow: hidden;
      position: relative;
      z-index: 1;

      &::after {
        animation: ${InProgress} 1.2s ease-in-out alternate both infinite;
        background-color: ${(props) =>
          darken(0.05, props.color || props.theme.PrimaryColor)};
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: -1;
      }

      &::before {
        background-color: ${(props) => props.color || props.theme.PrimaryColor};
        content: '';
        display: block;
        height: 100%;
        position: absolute;s
        right: 0;
        top: 0;
        width: 100%;
        z-index: -2;
      }

      &:hover {
        background: none;
      }
    `}
`;

export const PaleButton = styled.button`
  background: none;
  border: 1px solid ${(props) => props.color || props.theme.PrimaryColor};
  border-radius: ${(props) => (props.rounded ? '22px' : props.theme.PrimaryRadius)};
  box-sizing: border-box;
  color: ${(props) => props.color || props.theme.PrimaryColor};
  cursor: pointer;
  display: inline-block;
  font-family: ${(props) => props.theme.PrimaryFont};
  font-size: ${(props) => props.theme.FontSize_standard};
  line-height: 20px;
  padding: ${(props) => props.pad || '1em 1.6em'};
  text-align: center;
  transition: ${(props) => props.theme.PrimaryTransition};

  ${(props) =>
    props.margin &&
    css`
      margin: ${(props) => props.margin};
    `}

  ${(props) =>
    props.width &&
    css`
      min-width: ${(props) => props.width};
    `}

  ${(props) =>
    props.iconed &&
    css`
      min-width: 44px;
      padding: 1em 1.6em;
    `}

  ${(props) =>
    props.iconLeft &&
    css`
      & i {
        margin-left: -6px;
        padding-right: 4px;
      }
    `}

  ${(props) =>
    props.iconRight &&
    css`
      & i {
        margin-right: -6px;
        padding-left: 4px;
      }
    `}

  ${(props) =>
    props.small &&
    css`
      line-height: 16px;
      min-height: 30px;
      padding: 6px 14px;

      ${(props) =>
        props.iconed &&
        css`
          padding: 6px 6px;
          min-width: 30px;
        `}
    `}

  ${(props) =>
    props.block &&
    css`
      display: block;
      width: 100%;
    `}

  &:hover {
    background-color: ${(props) =>
      lighten(0.4, props.color || props.theme.PrimaryColor)};
  }

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  ${(props) =>
    props.disabled &&
    css`
      border-color: ${(props) =>
        desaturate(0.7, lighten(0.35, props.color || props.theme.PrimaryColor))};
      color: ${(props) =>
        desaturate(0.9, lighten(0.1, props.color || props.theme.PrimaryColor))};

      &:hover {
        background: none;
        border-color: ${(props) =>
          desaturate(0.7, lighten(0.35, props.color || props.theme.PrimaryColor))};
        color: ${(props) =>
          desaturate(0.9, lighten(0.1, props.color || props.theme.PrimaryColor))};
      }
    `}

  ${(props) =>
    props.progress &&
    css`
      overflow: hidden;
      position: relative;
      z-index: 1;

      &::after {
        animation: ${InProgress} 1.2s ease-in-out alternate both infinite;
        background-color: ${(props) =>
          lighten(0.47, props.color || props.theme.PrimaryColor)};
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: -1;
      }

      &:hover {
        background: none;
      }
    `}
`;
