const layerCategoriesConfig = {
  settlementType: {
    name: 'Settlement Types',
    fields: [
      {
        id: 'name',
        index: 0,
        key: 'name',
        name: 'Settlement Name',
        selected: false,
        default: true,
      },
      {
        id: 'population',
        index: 1,
        key: 'population',
        name: 'Population',
        selected: false,
        default: true,
      },
      {
        id: 'alternate_name',
        index: 2,
        key: 'alternate_name',
        name: 'Alternate Name',
        selected: false,
        default: false,
      },
      {
        id: 'state_name',
        index: 3,
        key: 'state_name',
        name: 'State',
        selected: false,
        default: false,
      },
      {
        id: 'ward_name',
        index: 4,
        key: 'ward_name',
        name: 'Ward',
        selected: false,
        default: false,
      },
      {
        id: 'lga_name',
        index: 5,
        key: 'lga_name',
        name: 'LGA',
        selected: false,
        default: false,
      },
    ],
  },
  adminBoundary: {
    name: 'Administrative Boundaries',
  },
  poi: {
    name: 'Points of Interest',
  },
};

const citation = `
This special use map was produced to support team planning. It was
designed specifically for planning intervention team assignments and is
not designed to be a topographic map.
`;

const disclaimer = `
The content has been compiled from commercial satellite image sources and
includes settlement names and points of interest from field data
collection.
`;

const legendImageNames = {
  cardinalPoints: 'showCardinalPoints',
  clientLogo: 'showClientLogo',
  companyLogo: 'showCompanyLogo',
  scaleBar: 'showScaleBar',
};

const locationBorderStyle = {
  type: 'line',
  color: '#AC4FC4',
  lineLayout: { visibility: 'visible' },
  linePaint: {
    'line-blur': 1,
    'line-color': '#AC4FC4',
    'line-opacity': 0.8,
    'line-width': 3,
  },
};

export default {
  citation,
  disclaimer,
  layerCategoriesConfig,
  legendImageNames,
  locationBorderStyle,
};
