import React from 'react';

import ExplorerSidebar from '../../components/ExplorerSidebar';
import MapContent from '../../components/MapContent';
import { Boxed, Grid } from '../../components/styled-components';

const ExplorerView = () => (
  <Grid default="24rem auto" tablet="22rem auto" mobile="100%">
    <ExplorerSidebar />

    <Boxed className="map-content map-explore-box">
      <Boxed background="LightCyan" width="100%" height="100%" pad="0">
        <MapContent />
      </Boxed>
    </Boxed>
  </Grid>
);

export default ExplorerView;
