import { applyMiddleware, createStore, StoreEnhancer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import middlewares from './middlewares';
import allReducers from './reducers';

const storeFactory = () => {
  const enhancer: StoreEnhancer = applyMiddleware(...middlewares);

  if (process.env.NODE_ENV !== 'production') {
    const enhanceWithDevTools = composeWithDevTools(enhancer);
    return createStore(allReducers, enhanceWithDevTools);
  }

  return createStore(allReducers, enhancer);
};

const store = storeFactory();
export default store;
