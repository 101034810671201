// API Endpoints
const API_URL = process.env.REACT_APP_API_URL || '';

const GDB = 'gdb';
const GDB_LOCATIONS = `${GDB}/admin-levels`;
const LOCATIONS = `${API_URL}/${GDB_LOCATIONS}`;

const LAYER_FEATURES = `${API_URL}/map/data-layer-features`;
const MAP_TEMPLATE = `${API_URL}/map/template`;
const MAP_DOCUMENT = `${API_URL}/map/document`;

const DOWNLOAD_MAP = `${API_URL}/output/pdf`;
const SAVE_MAP = `${API_URL}/map/save`;

export default {
  API_URL,
  DOWNLOAD_MAP,
  LAYER_FEATURES,
  LOCATIONS,
  MAP_DOCUMENT,
  MAP_TEMPLATE,
  SAVE_MAP,
};
