import debounce from 'lodash/debounce';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadDataLayerFeatures } from '../../redux/actions/layers';
import {
  clearLocations,
  getLocations,
  localizeMap,
  toggleModal,
} from '../../redux/actions/location';
import { DefaultRootState, Location } from '../../types';
import {
  AutoSuggest,
  Boxed,
  Button,
  Grid,
  ModalComponent,
  PaleButton,
  Theme,
} from '../styled-components';
import { AdminLevelLabel } from './AdminLevelLabel';

const LocalizationModal = () => {
  const [textInput, setTextInput] = useState('');
  const [location, setLocation] = useState(null);
  const {
    loader: { locations: loading },
    location: { showModal, locations },
    mapDocument: {
      current: {
        mapTemplate: { id: mapTemplateId },
      },
    },
  } = useSelector((state: DefaultRootState) => state);
  const dispatch = useDispatch();

  const handleInputChange = (_, { newValue }) => {
    setTextInput(newValue);
  };

  const renderLocation = (location: Location) => (
    <div>
      <AdminLevelLabel name={location.level} />
      {location.name}
      <span>{location.parents}</span>
    </div>
  );

  const debouncedGetLocations = useRef(
    debounce((target: any) => dispatch(getLocations(target.value)), 1000),
  ).current;

  const getLocationValue = (location: Location) => location.name;

  const onLocationSelected = (_, { suggestion }) => {
    setLocation(suggestion);
  };

  const propagateToMap = (location) => {
    dispatch(localizeMap(location));
  };

  const loadFeatures = (mapTemplateId, location) => {
    dispatch(loadDataLayerFeatures(mapTemplateId, location.level, location.value));
  };

  const onConfirm = () => {
    if (location !== null) {
      propagateToMap(location);
      loadFeatures(mapTemplateId, location);
    }
    dispatch(toggleModal(false));
    clearLocations();
  };

  const onReset = () => {
    setTextInput('');
    setLocation(null);
  };

  const onCancel = () => {
    dispatch(clearLocations());
    dispatch(toggleModal(false));
  };

  const inputProps = {
    placeholder: 'Type a State, LGA or Ward',
    value: textInput,
    onChange: handleInputChange,
  };

  const statusIcon =
    textInput && !loading ? (
      <i className="mp-cancel-1" onClick={onReset} style={{ cursor: 'pointer' }} />
    ) : (
      <i className={loading ? 'mp-spin5 animate-spin' : 'mp-search'} />
    );

  return (
    <div>
      <ModalComponent
        color={Theme.SecondaryColor}
        onClose={onCancel}
        open={showModal}
        title="ENTER LOCATION"
        width="500px"
        footer={
          <div>
            <PaleButton iconed margin="0 1rem" onClick={onCancel}>
              <i className="mp-left" />
              Cancel
            </PaleButton>
            <Button disabled={!location} iconed onClick={onConfirm}>
              OK <i className="mp-ok-circle" />
            </Button>
          </div>
        }
      >
        <Grid pad="50px" default="auto" tablet="100%" mobile="100%">
          <Boxed>
            <div className="textbox-autosuggest">
              <Boxed>
                <AutoSuggest
                  getSuggestionValue={getLocationValue}
                  inputProps={inputProps}
                  onSuggestionsClearRequested={() => dispatch(clearLocations())}
                  onSuggestionSelected={onLocationSelected}
                  onSuggestionsFetchRequested={debouncedGetLocations}
                  renderSuggestion={renderLocation}
                  suggestions={locations}
                  statusIcon={statusIcon}
                />
              </Boxed>
            </div>
          </Boxed>
        </Grid>
      </ModalComponent>
    </div>
  );
};

export default LocalizationModal;
