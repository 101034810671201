import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleMetadata } from '../../redux/actions/mapDocument';
import { DefaultRootState } from '../../types';
import { Boxed, H5, SimpleCheckbox } from '../styled-components';

const SystemLegendPane = () => {
  const {
    mapDocument: {
      current: {
        metadata: { showCompanyLogo, showClientLogo, showCardinalPoints, showScaleBar },
      },
    },
  } = useSelector((state: DefaultRootState) => state);

  const dispatch = useDispatch();
  const noop = () => {};

  return (
    <Boxed pad="1.4rem 2rem" className="side-p-container">
      <H5>Show or hide specific legends</H5>
      <SimpleCheckbox
        type="checkbox"
        icon="icon mp-ok"
        label="Show Localisation"
        onChange={noop}
      />
      <SimpleCheckbox
        type="checkbox"
        icon="icon mp-ok"
        label="Cardinal Directions"
        checked={showCardinalPoints ? 'checked' : ''}
        onChange={() => {
          dispatch(toggleMetadata('showCardinalPoints', !showCardinalPoints));
        }}
      />
      <SimpleCheckbox
        type="checkbox"
        icon="icon mp-ok"
        label="Show Scale"
        checked={showScaleBar ? 'checked' : ''}
        onChange={() => {
          dispatch(toggleMetadata('showScaleBar', !showScaleBar));
        }}
      />
      <SimpleCheckbox
        type="checkbox"
        icon="icon mp-ok"
        label="Show eHealth Africa Logo"
        checked={showCompanyLogo ? 'checked' : ''}
        onChange={() => {
          dispatch(toggleMetadata('showCompanyLogo', !showCompanyLogo));
        }}
      />
      <SimpleCheckbox
        type="checkbox"
        icon="icon mp-ok"
        label="Show Client Logo"
        checked={showClientLogo ? 'checked' : ''}
        onChange={() => {
          dispatch(toggleMetadata('showClientLogo', !showClientLogo));
        }}
      />
    </Boxed>
  );
};

export default SystemLegendPane;
