import { apiClient } from '../../utils';

export default () => (next: any) => (action: any) => {
  const { promise, types, ...rest } = action;
  if (!promise) return next(action);

  const [REQUEST, SUCCESS, FAILURE] = types;
  next({ ...rest, type: REQUEST, loading: true });
  return promise(apiClient)
    .then(({ data }: any) => {
      next({ ...rest, payload: data, type: SUCCESS, loading: false });
    })
    .catch(({ message, error, status }: any) => {
      next({
        ...rest,
        error: { message, error, status },
        type: FAILURE,
        loading: false,
      });
    });
};
