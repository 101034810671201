import { lighten, transparentize } from 'polished';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

const TabItem = styled.li`
  align-items: center;
  border: 1px solid transparent;
  color: ${(props) => props.theme.PrimaryGrey};
  cursor: pointer;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0.6rem 2rem 0.6rem 1.2rem;
  position: relative;
  transition: all 0.2s;

  &::after {
    background: ${(props) => props.theme.PrimaryColor};
    content: '';
    height: 2px;
    left: 50%;
    margin: 0 -1px;
    position: absolute;
    top: -1px;
    transition: all 0.2s ease-out;
    width: 0;
  }

  ${(props) =>
    !props.disabled
      ? css`
          &.active {
            background: ${(props) => props.theme.PrimaryWhite};
            border: 1px solid ${lighten(0.5, props.theme.PrimaryFontColor)};
            border-bottom: 1px solid ${(props) => props.theme.PrimaryWhite};
            color: ${(props) => props.theme.PrimaryColor};

            &::after {
              width: calc(100% + 2px);
              left: 0;
            }
          }

          &:hover,
          &:focus {
            color: ${(props) => props.theme.PrimaryColor};
          }
        `
      : css`
          opacity: 0.4;
        `}

  i {
    margin-right: 0.4em;
  }

  span {
    display: block;
  }

  .tab-label {
    font-size: ${(props) => props.theme.FontSize_standard};
  }

  .tab-sublabel {
    color: ${(props) => props.theme.PrimaryGrey};
    font-size: ${(props) => props.theme.FontSize_s};
  }
`;

const TabItems = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0 1rem;
  position: relative;
  // z-index: 10;
`;

const TabContent = styled.div`
  background: ${(props) => props.theme.PrimaryWhite};
  border-top: 1px solid ${(props) => lighten(0.5, props.theme.PrimaryFontColor)};
  margin-top: -1px;
  padding: 1rem 1.2rem;
  position: relative;
  width: 100%;
  z-index: 1;

  ${(props) =>
    props.bg &&
    css`
      background: ${(props) => props.theme.PrimaryWhite};
    `}
`;

const TabsContainer = styled.div`
  font-size: ${(props) => props.theme.FontSize_standard};
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;

  ${(props) =>
    props.bg &&
    css`
      background: ${(props) => props.theme.PrimaryBackground};
    `}

  ${(props) =>
    props.padding &&
    css`
      padding: ${(props) => props.padding};
    `}

  ${(props) =>
    props.block &&
    css`
      ${TabItem} {
        flex: 1;
      }
    `}

  ${(props) =>
    props.minimal &&
    css`
      ${TabItems} {
        padding: 0;
        z-index: 10;
      }

      ${TabItem} {
        &::after {
          bottom: 0;
          top: auto;
        }

        &.active {
          background: none;
          border: 1px solid transparent;
        }
      }

      ${TabContent} {
        background: none;
        border-top: 1px solid ${transparentize(0.8, props.theme.PrimaryFontColor)};
        margin-top: -2px;
      }
    `}

  ${(props) =>
    props.shadow &&
    css`
      ${TabItems} {
        padding: 0;
        z-index: 0;
      }

      ${TabItem} {
        &.active {
          border: 1px solid transparent;
          box-shadow: 0 0 1px ${transparentize(0.9, props.theme.PrimaryDark)},
            0 3px 4px ${transparentize(0.9, props.theme.PrimaryGreyDark)};
        }
      }

      ${TabContent} {
        border-radius: ${(props) => props.theme.PrimaryRadius};
        border-top: 1px solid ${(props) => props.theme.PrimaryBackground};
        box-shadow: 0 1px 1px ${transparentize(0.9, props.theme.PrimaryDark)},
          0 3px 4px ${transparentize(0.9, props.theme.PrimaryGreyDark)};
      }
    `}
`;

export const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState<string>();

  const getChildren = () => {
    const list = !Array.isArray(props.children) ? [props.children] : props.children;
    return list.filter((child) => child);
  };
  const children = getChildren();

  const onClickTabItem = (label) => {
    setActiveTab(label);
  };

  useEffect(() => {
    try {
      const labels = children.map((child) => child.props.label);
      if (labels.includes(activeTab)) return;
      // new set of tabs
      setActiveTab(labels[0]);
    } catch (err) {
      setActiveTab(undefined);
    }
  }, [activeTab, children]);

  return (
    <TabsContainer {...props} className="tabs-container">
      <TabItems {...props} className="tabs-menu">
        {children.map((child, index) => {
          const { label, icon, disabled, sublabel } = child.props;
          const className = `tab-item${activeTab === label ? ' active' : ''}`;

          return (
            <TabItem
              {...props}
              className={className}
              disabled={disabled}
              key={`item_${index}`}
              label={label}
              onClick={() => !disabled && onClickTabItem(label)}
              sublabel={sublabel}
            >
              {icon && <i className={icon} />}
              <div>
                <span className="tab-label">{label}</span>
                {sublabel && <span className="tab-sublabel">{sublabel}</span>}
              </div>
            </TabItem>
          );
        })}
      </TabItems>

      <TabContent {...props} className="tab-content">
        {children
          .filter((child) => child && child.props.label === activeTab)
          .map((child) => child.props.children)}
      </TabContent>
    </TabsContainer>
  );
};

Tabs.propTypes = {
  bg: PropTypes.bool,
  block: PropTypes.bool,
  shadow: PropTypes.bool,
  minimal: PropTypes.bool,
  label: PropTypes.string,
  sublabel: PropTypes.string,
  icon: PropTypes.string,
  padding: PropTypes.string,
  children: PropTypes.node,
};
