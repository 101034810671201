import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

export const TopBar = styled.div`
  align-items: center;
  background: ${(props) => props.bg || props.theme.PrimaryDark};
  color: ${(props) => props.theme.PrimaryWhite};
  display: flex;
  font-size: ${(props) => props.theme.FontSize_standard};
  height: ${(props) => props.height || props.theme.TopBarHeight};
  margin: 0;
  padding: 1rem;
  position: relative;
  width: 100%;

  ${(props) =>
    props.sticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 5;
    `}
`;

TopBar.propTypes = {
  bg: PropTypes.string,
  height: PropTypes.string,
  sticky: PropTypes.bool,
};
