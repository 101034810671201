import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { DefaultRootState, Dictionary, Field } from '../../types';
import { Boxed, Button, SimpleSelect } from '../styled-components';

const ColumnDataPane = ({ fields, onAddField }) => {
  const {
    mapDocument: {
      current: { id: mapDocumentId },
    },
  } = useSelector((state: DefaultRootState) => state);

  const [selectedOption, setSelectedOption] = useState<Dictionary | null>();
  const [selectedField, setCurrentField] = useState<Field>();

  const options = (fields || [])
    .filter((field: Field) => !field.default && !field.selected)
    .map((field) => ({ value: field.key, label: field.name }));

  const addField = () => {
    onAddField({ ...selectedField, selected: true });
    setSelectedOption(null);
  };

  if (!(options.length > 0 && !mapDocumentId)) return <React.Fragment />;

  return (
    <Boxed pad="1.4rem 2rem">
      <SimpleSelect
        options={options}
        label="Add data column to table"
        placeholder="Select Data Column"
        forminput
        required
        value={selectedOption}
        onChange={(option) => {
          const field = fields.find((field) => {
            return field.key === option.value;
          });
          setSelectedOption(option);
          setCurrentField(field);
        }}
      />
      <Button iconed className="float-right" onClick={addField}>
        Add Column <i className="mp-plus" />
      </Button>
    </Boxed>
  );
};

export default ColumnDataPane;
