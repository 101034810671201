import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Boxed,
  Button,
  Grid,
  Label,
  SimpleSelect,
} from '../../components/styled-components';
import { changePageSetup } from '../../redux/actions/mapDocument';
import { DefaultRootState } from '../../types';

const paperSizeOptions = [
  { value: 'A4', label: 'A4' },
  { value: 'A3', label: 'A3' },
  { value: 'A2', label: 'A2' },
  { value: 'A1', label: 'A1' },
  { value: 'A0', label: 'A0' },
];

const orientationOptions = [
  {
    id: 'portrait',
    className: 'mp-vertical-view',
  },
  {
    id: 'landscape',
    className: 'mp-horizontal-view',
  },
];

const LayoutBar = () => {
  const {
    mapDocument: {
      current: {
        id: mapDocumentId,
        pageSetup: { size, orientation },
      },
    },
  } = useSelector((state: DefaultRootState) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [orientation]);

  const selected =
    paperSizeOptions.find((item) => item.value === size) || paperSizeOptions[0];

  return (
    <Boxed>
      <Grid pad="10px" default="auto auto" tablet="100%" mobile="100%">
        <SimpleSelect
          onChange={({ value }) => {
            dispatch(changePageSetup({ size: value }));
          }}
          options={paperSizeOptions}
          defaultValue={selected}
          type="select"
          value={selected}
          placeholder="SELECT PAPER SIZE"
          forminput
          required
          disabled={!!mapDocumentId}
        />

        <Boxed className="table-placement">
          <div>
            <Label>Select Orientation</Label>
          </div>
          {orientationOptions.map((item) => (
            <Button
              key={item.id}
              light={orientation !== item.id}
              margin="3px 5px 0 0"
              pad="7px 12px"
              onClick={() => {
                dispatch(changePageSetup({ orientation: item.id }));
              }}
              title={item.id}
            >
              <i className={item.className} />
            </Button>
          ))}
        </Boxed>
      </Grid>
    </Boxed>
  );
};

export default LayoutBar;
