import { feature, featureCollection } from '@turf/helpers';
import React from 'react';
import ReactMapboxGl, {
  GeoJSONLayer,
  ScaleControl,
  ZoomControl,
} from 'react-mapbox-gl';
import { useSelector } from 'react-redux';

import { Boxed } from '../components/styled-components';
import { DefaultRootState } from '../types';
import { constants, helpers } from '../utils';

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_GL_TOKEN || '',
  logoPosition: 'bottom-left',
});

const MapContent = () => {
  const {
    dataLayer: { dataLayerFeatures, dataLayerStyles },
    location: {
      currentLocation: { boundingBox, boundary, level, name },
      containerStyle,
    },
    mapDocument: {
      current: {
        baseLayer,
        mapTemplate: { dataLayers },
      },
    },
  } = useSelector((state: DefaultRootState) => state);

  const style = baseLayer.source;
  const checkedLayers = helpers.getCheckedLayers(dataLayers, dataLayerFeatures);
  const mapLayers = [
    <Boxed className="scale-control" key="scale-control">
      <ScaleControl measurement="km" position="top-left" />
    </Boxed>,
    <Boxed className="zoom-control" key="zoom-control">
      <ZoomControl position="top-right" />
    </Boxed>,
    <GeoJSONLayer
      key={`${level}-${name}`}
      data={featureCollection([feature(boundary)])}
      {...constants.locationBorderStyle}
    />,
  ];

  helpers
    .getMapboxStyles(checkedLayers, dataLayerFeatures, dataLayerStyles)
    .forEach(({ id, type, layout, paint, featureCollection }) => {
      const styles = { [`${type}Layout`]: layout, [`${type}Paint`]: paint };
      mapLayers.push(<GeoJSONLayer key={id} data={featureCollection} {...styles} />);
    });

  return (
    <Map containerStyle={containerStyle} fitBounds={boundingBox} style={style}>
      {mapLayers}
    </Map>
  );
};

export default MapContent;
