import React from 'react';
import { useSelector } from 'react-redux';

import { DefaultRootState } from '../../types';
import { helpers } from '../../utils';
import { Boxed, H5, SimpleCheckbox } from '../styled-components';

const DataLegendPane = () => {
  const {
    dataLayer: { dataLayerFeatures },
    mapDocument: {
      current: {
        mapTemplate: { dataLayers },
      },
    },
  } = useSelector((state: DefaultRootState) => state);

  const checkedLayers = helpers.getCheckedLayers(dataLayers, dataLayerFeatures);

  return (
    <Boxed margin="1.5rem 0" pad="1.4rem 2rem" className="side-p-container">
      {!!(checkedLayers || []).length && (
        <>
          <H5>Data Legends</H5>
          {checkedLayers.map((layer) => (
            <SimpleCheckbox
              key={layer.id}
              type="checkbox"
              icon="icon mp-ok"
              checked="checked"
              badge={<small>({dataLayerFeatures[layer.id].features.length})</small>}
              label={layer.name}
              disabled
            />
          ))}
        </>
      )}
    </Boxed>
  );
};

export default DataLegendPane;
