import React, { useState } from 'react';

import {
  Boxed,
  Button,
  Grid,
  Input,
  ModalComponent,
  PaleButton,
  SimpleSelect,
  Theme,
} from '../../../components/styled-components';
import { Field } from '../../../types';

const DataColumnModal = (props: {
  field?: Field;
  saveField: (field: Field) => void;
  dismiss: () => void;
}) => {
  const [text, setText] = useState<string>('');
  const { field, saveField, dismiss } = props;
  const option = field ? { value: field.key, label: field.name } : null;

  return (
    <div>
      <ModalComponent
        color={Theme.SecondaryColor}
        onClose={dismiss}
        open={!!field}
        title="EDIT DATA COLUMN NAME"
        width="600px"
        footer={
          <div>
            <PaleButton iconed onClick={dismiss} margin="0 1rem">
              <i className="mp-left" />
              Cancel
            </PaleButton>

            <Button
              iconed
              disabled={!field}
              color={Theme.PrimaryColor}
              onClick={() => {
                if (field) saveField({ ...field, name: text });
              }}
            >
              OK <i className="mp-ok-circle" />
            </Button>
          </div>
        }
      >
        <Grid pad="50px" default="auto" tablet="100%" mobile="100%">
          <Boxed>
            <Input
              type="text"
              label="Data Column"
              placeholder="ENTER NEW DATA COLUMN NAME"
              required
              value={text || ''}
              onChange={({ target: { value } }) => setText(value)}
              forminput
            />

            <div className="edit-column-select">
              <SimpleSelect
                options={option ? [option] : []}
                label="Field Name"
                placeholder="Enter Field Name"
                value={option}
                forminput
                required
                isDisabled
              />
            </div>
          </Boxed>
        </Grid>
      </ModalComponent>
    </div>
  );
};

export default DataColumnModal;
