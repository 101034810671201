import { combineReducers } from 'redux';

import { default as dataLayer } from './dataLayer';
import { default as loader } from './loader';
import { default as location } from './location';
import { default as mapDocument } from './mapDocument';

export default combineReducers({
  dataLayer,
  loader,
  location,
  mapDocument,
});
