import React from 'react';

import {
  Badge,
  Boxed,
  Grid,
  H5,
  P,
  Span,
  Theme,
} from '../../components/styled-components';
import { helpers } from '../../utils';
import SideDrawer from './SideDrawer';

const LayerCategory = ({
  name,
  subtitle,
  description,
  dataLayers,
  dataLayersContents,
  loadingFeatures,
  onToggleDataLayer,
  onToggleLayerLabel,
  editable,
}) => {
  const checkedLayers = helpers.getCheckedLayers(dataLayers, dataLayersContents);
  return (
    <Boxed pad="1.4rem 2rem" className="side-bar-dropdown">
      <Grid default="auto 1rem">
        <Boxed>
          <H5>{name}</H5>
          <P size={Theme.FontSize_s}>{subtitle}</P>
          <Span size={Theme.FontSize_s}>
            <Badge>
              <b>{checkedLayers.length}</b> / {dataLayers.length}
            </Badge>{' '}
            selections
          </Span>
        </Boxed>

        <Boxed height="100%" className="icon">
          <i className="mp-right-open" />
        </Boxed>
      </Grid>

      <SideDrawer
        name={name}
        description={description}
        dataLayers={dataLayers}
        dataLayersContents={dataLayersContents}
        loadingFeatures={loadingFeatures}
        onToggleDataLayer={onToggleDataLayer}
        onToggleLayerLabel={onToggleLayerLabel}
        editable={editable}
      />
    </Boxed>
  );
};

export default LayerCategory;
