import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

const bar = keyframes`
  0%,
  100% {
    box-shadow:
      0 0 0 ${(props) => props.theme.PrimaryColor},
      0 0 0 ${(props) => props.theme.PrimaryColor};
  }
  50% {
    box-shadow:
      0 -8px 0 ${(props) => props.theme.PrimaryColor},
      0 8px 0 ${(props) => props.theme.PrimaryColor};
  }
`;

export const BarLoader = styled.div`
  animation: ${bar} 1s ease-in-out infinite;
  animation-delay: 0.4s;
  background: ${(props) => props.theme.PrimaryColor};
  display: inline-block;
  height: 48px;
  position: relative;
  top: 50%;
  width: 10px;

  &::after,
  &::before {
    animation: ${bar} 1s ease-in-out infinite;
    background: ${(props) => props.theme.PrimaryColor};
    content: '';
    height: 48px;
    position: absolute;
    width: 10px;
  }

  &::before {
    animation-delay: 0.2s;
    right: 18px;
  }

  &::after {
    animation-delay: 0.6s;
    left: 18px;
  }

  ${(props) =>
    props.margin &&
    css`
      margin: ${(props) => props.margin};
    `};
`;

BarLoader.propTypes = {
  margin: PropTypes.string,
};
