import React, { ReactElement } from 'react';

import { DataTable, Dictionary, Field } from '../../../types';

type SettlementsPageProps = { dataTable: DataTable };
const popField = 'population_properties';

const SettlementsPage = ({ dataTable }: SettlementsPageProps): ReactElement => {
  if (!dataTable.display) return <React.Fragment />;

  const { fields, paginatedTables } = dataTable;
  const list = (fields || []).filter((field: Field) => field.default || field.selected);

  return (
    <>
      {paginatedTables.map((tablePage, i) => (
        <div key={i} className="map-container">
          <div className="table-box">
            <table>
              <thead>
                <tr>
                  <th key="field_sn">SN</th>

                  {list.map((field: Field) => (
                    <th key={field.id}>{field.name}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {tablePage.map((tableRow: Dictionary) => (
                  <tr key={tableRow.id}>
                    <td data-column="Row">{tableRow.sn}</td>

                    {list.map((field: Field) => (
                      <td data-column={field.name} key={`${field.id}-${tableRow.id}`}>
                        {field.key === 'population'
                          ? (tableRow[popField] && tableRow[popField].total_pop) || 0
                          : tableRow[field.key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </>
  );
};

export default SettlementsPage;
