import { transparentize } from 'polished';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { media } from './theme';

export const Panel = styled.div`
  font-family: ${(props) => props.theme.PrimaryFont};
  font-size: ${(props) => props.theme.FontSize_standard};
  margin: 0;
  padding: 0;
`;

export const LayoutWrapper = styled.div`
  background: ${(props) => props.theme.PrimaryBackground};
  display: flex;
  font-family: ${(props) => props.theme.PrimaryFont};
  font-size: ${(props) => props.theme.FontSize_standard};
  height: 100%;
  justify-content: stretch;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  & * {
    box-sizing: border-box;
  }
`;

export const Boxed = styled.div`
  background: ${(props) => props.bgcolor};
  display: block;
  position: relative;

  ${(props) =>
    props.padHorizontal &&
    css`
      padding-left: ${(props) => props.padHorizontal};
      padding-right: ${(props) => props.padHorizontal};
    `}

  ${(props) =>
    props.padVertical &&
    css`
      padding-bottom: ${(props) => props.padVertical};
      padding-top: ${(props) => props.padVertical};
    `}

  ${(props) =>
    props.pad &&
    css`
      padding: ${(props) => props.pad};
    `}

  ${(props) =>
    props.align &&
    css`
      text-align: ${(props) => props.align};
    `}

  ${(props) =>
    props.margin &&
    css`
      margin: ${(props) => props.margin};
    `}

  ${(props) =>
    props.color &&
    css`
      color: ${(props) => props.color};
    `}

  ${(props) =>
    props.position &&
    css`
      position: ${(props) => props.position};
    `}

  ${(props) =>
    props.background &&
    css`
      background: ${(props) => props.background};
    `}

  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${(props) => props.maxWidth};
    `}

  ${(props) =>
    props.width &&
    css`
      width: ${(props) => props.width};
    `}

  ${(props) =>
    props.minHeight &&
    css`
      min-height: ${(props) => props.minHeight};
    `}

  ${(props) =>
    props.height &&
    css`
      height: ${(props) => props.height};
    `}

  ${(props) =>
    props.verticalAlign &&
    css`
      align-items: ${(props) => props.verticalAlign};
      display: flex;
    `}

  ${(props) =>
    props.fullHeight &&
    css`
      height: inherit;
      min-height: inherit;
    `}

  ${(props) =>
    props.shadow &&
    css`
      box-shadow: 0 1px 1px ${transparentize(0.9, props.theme.PrimaryDark)},
        0 3px 4px ${transparentize(0.9, props.theme.PrimaryGreyDark)};
    `}

  ${(props) =>
    props.shadowLight &&
    css`
      box-shadow: 0 1px 3px ${transparentize(0.96, props.theme.PrimaryDark)},
        0 2px 12px ${transparentize(0.95, props.theme.PrimaryGrey)};
    `}

  ${(props) =>
    props.rounded &&
    css`
      border-radius: ${(props) => props.theme.SecondaryRadius};
    `}

  ${(props) =>
    props.outline &&
    css`
      border: 1px solid ${(props) => props.theme.PrimaryColor_light_40};
    `}

  ${(props) =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid ${(props) => props.borderBottom};
    `}
`;

export const Grid = styled.div`
  display: grid;
  gap: ${(props) => props.pad};
  grid-template-columns: ${(props) => props.default};
  padding: ${(props) => props.padall || '0'};
  width: 100%;

  ${(props) =>
    props.padHorizontal &&
    css`
      column-gap: ${(props) => props.padHorizontal};
    `}

  ${(props) =>
    props.padVertical &&
    css`
      row-gap: ${(props) => props.padVertical};
    `}

  ${(props) =>
    props.autoRow &&
    css`
      grid-auto-rows: ${(props) => props.autoRow};
    `}

  ${media.desktop`
    grid-template-columns: ${(props) => props.desktop};
  `}

  ${media.tablet`
    grid-template-columns: ${(props) => props.tablet};
  `}

  ${media.phone`
    grid-template-columns: ${(props) => props.mobile};
  `}
`;

Grid.defaultProps = {
  default: 'repeat(6, 1fr)',
  mobile: '1fr',
  pad: '0',
  tablet: 'repeat(3, 1fr)',
};
Grid.propTypes = {
  default: PropTypes.string,
  mobile: PropTypes.string,
  pad: PropTypes.string,
  tablet: PropTypes.string,
};
