import React from 'react';
import styled from 'styled-components';

import { Theme } from '../styled-components/theme';

const LevelLabel = styled.div`
  align-items: center;
  background: ${(props) => props.color};
  border-radius: 50%;
  color: ${(props) => props.theme.PrimaryWhite};
  display: inline-flex;
  font-size: ${(props) => props.theme.FontSize_s};
  height: 24px;
  justify-content: center;
  margin-right: 10px;
  width: 24px;

  &::before {
    content: '${(props) => props.abbr}';
  }
`;

const LEVELS = {
  lga: {
    name: 'LGA',
    color: Theme.PrimaryGreen,
    abbr: 'LG',
  },
  settlement: {
    name: 'Settlement',
    color: Theme.PrimaryBorderColor,
    abbr: 'SM',
  },
  state: {
    name: 'State',
    color: Theme.PrimaryRed,
    abbr: 'ST',
  },
  ward: {
    name: 'Ward',
    color: Theme.PrimaryOrange,
    abbr: 'WD',
  },
};

export const AdminLevelLabel = (props) => {
  const selection = LEVELS[props.name.toLowerCase()] || {};

  return <LevelLabel color={selection.color} abbr={selection.abbr} />;
};
