import styled from 'styled-components';

// NOTE: unused
export const H1 = styled.h1`
  color: ${(props) => props.color || props.theme.PrimaryDark};
  font-family: ${(props) => props.theme.PrimaryFont};
  font-size: ${(props) => props.theme.FontSize_xxl};
  font-weight: lighter;
  letter-spacing: 0.7;
  margin: ${(props) => props.margin || '0.4em 0'};
  padding: 0;
`;

export const H2 = styled.h2`
  color: ${(props) => props.color || props.theme.PrimaryDark};
  font-family: ${(props) => props.theme.PrimaryFont};
  font-size: ${(props) => props.theme.FontSize_m};
  font-weight: 600;
  letter-spacing: 0.5;
  margin: ${(props) => props.margin || '0.4em 0'};
  padding: 0;
`;

export const H3 = styled.h3`
  color: ${(props) => props.color || props.theme.PrimaryDark};
  font-family: ${(props) => props.theme.PrimaryFont};
  font-size: ${(props) => props.theme.FontSize_l};
  font-weight: 700;
  letter-spacing: 0.4;
  margin: ${(props) => props.margin || '0 0 0.4em'};
  padding: 0;
  text-transform: uppercase;
`;

export const H4 = styled.h4`
  color: ${(props) => props.color || props.theme.PrimaryDark};
  font-family: ${(props) => props.theme.PrimaryFont};
  font-size: ${(props) => props.theme.FontSize_standard};
  font-weight: 700;
  letter-spacing: 0.4;
  margin: ${(props) => props.margin || '0 0 0.4em'};
  padding: 0;
  text-transform: uppercase;
`;

export const H5 = styled.h5`
  color: ${(props) => props.color || props.theme.PrimaryDark};
  font-family: ${(props) => props.theme.PrimaryFont};
  font-size: ${(props) => props.theme.FontSize_m};
  font-weight: 700;
  letter-spacing: 0.4;
  margin: ${(props) => props.margin || '0 0 0.4em'};
  padding: 0;
`;

export const Label = styled.span`
  color: ${(props) => props.color || props.theme.PrimaryGrey};
  display: inline-block;
  font-family: ${(props) => props.theme.PrimaryFont};
  font-size: ${(props) => props.theme.FontSize_s};
  font-weight: normal;
  letter-spacing: 0.05em;
  margin: ${(props) => props.margin || '0'};
  padding: 0;
  text-transform: uppercase;
`;

export const Badge = styled.span`
  background: ${(props) => props.background || props.theme.PrimaryColor_light_40};
  border-radius: 1em;
  color: ${(props) => props.color || props.theme.PrimaryFontColor};
  display: inline-block;
  font-size: ${(props) => props.size || 'inherit'};
  line-height: ${(props) => props.theme.PrimaryLineHeight};
  padding: 0 0.5em;
`;

export const P = styled.p`
  color: ${(props) => props.color || props.theme.PrimaryFontColor};
  font-family: ${(props) => props.theme.PrimaryFont};
  font-size: ${(props) => props.size || props.theme.FontSize_standard};
  font-weight: normal;
  line-height: ${(props) => props.theme.PrimaryLineHeight};
  margin: ${(props) => props.margin || '0.4em 0 0.8em'};
  padding: 0;
`;

export const Span = styled.span`
  color: ${(props) => props.color || props.theme.PrimaryFontColor};
  font-family: inherit;
  font-size: ${(props) => props.size || props.theme.FontSize_standard};
  font-weight: inherit;
  line-height: ${(props) => props.theme.PrimaryLineHeight};
`;

export const A = styled.a`
  color: ${(props) => props.color || props.theme.PrimaryColor};
  display: inline-block;
  text-decoration: none;

  &:hover,
  &:focus {
    border-bottom: 1px dotted ${(props) => props.theme.PrimaryColor_light_10};
    color: ${(props) => props.theme.PrimaryColor_light_10};
    margin-bottom: -1px;
  }

  &:visited {
    color: ${(props) => props.theme.PrimaryColor_dark_10};
  }
`;

export const UL = styled.ul`
  font-family: ${(props) => props.theme.PrimaryFont};
  font-size: ${(props) => props.theme.FontSize_standard};
  font-weight: normal;
  line-height: ${(props) => props.theme.PrimaryLineHeight};
  margin: ${(props) => props.margin || '0.8em 0'};
  padding: 0;
`;

export const LI = styled.li`
  line-height: ${(props) => props.theme.PrimaryLineHeight};
  list-style: none;
  margin: ${(props) => props.margin || '0.4em 0 0.2em'};
  padding: 0 0 0 1.4rem;
  position: relative;

  &::before {
    border-bottom: 2px solid ${(props) => props.theme.PrimaryColor_light_10};
    content: '';
    display: block;
    height: 0.8em;
    left: 0;
    position: absolute;
    width: 0.8em;
  }
`;
