import React from 'react';

import DownloadBar from '../../components/Bars/DownloadBar';
import LayoutBar from '../../components/Bars/LayoutBar';
import MetadataBar from '../../components/Bars/MetadataBar';
import { Boxed, Grid } from '../../components/styled-components';

const PreviewBarStrip = () => {
  return (
    <Boxed outline className="tab-bar">
      <Grid pad="10px" default="auto auto auto" tablet="100%" mobile="100%">
        <MetadataBar />
        <LayoutBar />
        <DownloadBar />
      </Grid>
    </Boxed>
  );
};

export default PreviewBarStrip;
