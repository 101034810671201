import { saveAs } from 'file-saver';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { DataLayerParams, DefaultRootState, MapDocumentParam } from '../../types';
import { helpers, urls } from '../../utils';
import { Boxed, Button, ModalComponent, Theme } from '../styled-components';

const DownloadBar = () => {
  const {
    dataLayer: { dataLayerFeatures },
    location: { currentLocation: location },
    mapDocument: {
      current: {
        metadata,
        settlementFields,
        baseLayer: { id: baseLayerId },
        mapTemplate: { id: templateId, dataLayers },
        pageSetup,
      },
    },
  } = useSelector((state: DefaultRootState) => state);

  const [downloadStatus, setDownloadStatus] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string>();
  const layers: DataLayerParams[] = helpers.getDataLayerParams(
    dataLayers,
    dataLayerFeatures,
    settlementFields,
  );

  const handlePrint = async () => {
    if (downloadStatus) return;

    const { title, subtitle, authoredAt } = metadata;
    if (!subtitle || !title || !authoredAt) {
      setErrMessage('Please type "Title", "Subtitle" and "Authored date"...');
      return;
    }

    try {
      setDownloadStatus(true);
      const mapDocumentParam: MapDocumentParam = {
        baseLayerId,
        layers,
        location,
        metadata,
        pageSetup,
        templateId,
      };
      const blobRequest = await fetch(urls.DOWNLOAD_MAP, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(mapDocumentParam),
      });

      if (blobRequest.ok) {
        const pdfBlob = await blobRequest.blob();
        await saveAs(pdfBlob, `${title}.pdf`);
      } else {
        setErrMessage('Unexpected error while generating PDF');
      }
    } catch (error) {
      setErrMessage(error.message);
    } finally {
      setDownloadStatus(false);
    }
  };

  const buttonLabel = downloadStatus ? 'Generating PDF' : 'Download PDF';
  const buttonIcon = downloadStatus ? 'mp-spin5 animate-spin' : 'mp-download-1';

  return (
    <Boxed>
      <Button
        className="download-btn"
        color={Theme.PrimaryColor}
        disabled={downloadStatus}
        iconed
        margin="10px 0"
        onClick={handlePrint}
      >
        {buttonLabel}
        <i className={`${buttonIcon} pad-l-10`} />
      </Button>

      <ModalComponent
        color={Theme.SecondaryColor}
        onClose={() => setErrMessage(undefined)}
        open={!!errMessage}
        title="Error generating PDF"
        width="600px"
        footer={
          <Button
            iconed
            color={Theme.PrimaryRed}
            onClick={() => setErrMessage(undefined)}
          >
            OK
          </Button>
        }
      >
        <div>
          <i className="mp-info mr-2" />
          {errMessage}
        </div>
      </ModalComponent>
    </Boxed>
  );
};

export default DownloadBar;
