import React from 'react';

import { BarLoader, Boxed } from './styled-components';

const Loader = () => {
  return (
    <Boxed className="loader-wrapper">
      <BarLoader />
    </Boxed>
  );
};

export default Loader;
