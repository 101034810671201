import { constants, helpers } from '../../utils';
import {
  CHANGE_BASE_LAYER,
  CHANGE_PAGE_SETUP,
  GET_MAP_DOCUMENT_SUCCESS,
  GET_MAP_DOCUMENTS_SUCCESS,
  GET_MAP_TEMPLATE_SUCCESS,
  RESET_MAP_DOCUMENT,
  SET_CLIENT_LOGO,
  SET_MAP_METADATA,
  SET_TABLE_FIELD,
  TOGGLE_MAP_IMAGE,
} from '../types';

const {
  legendImageNames: { cardinalPoints, companyLogo, clientLogo, scaleBar },
  layerCategoriesConfig: { settlementType },
} = constants;

const poiCategory = {
  id: 1,
  name: 'Points of Interest',
  subtitle: 'Health Facilities, Schools, etc.',
};

const stCategory = {
  id: 2,
  name: 'Settlement Types',
  subtitle: 'Small Settlements, Built up areas etc.',
};

const abCategory = {
  id: 3,
  name: 'Administrative Boundaries',
  subtitle: 'State boundaries, Country boundaries etc.',
};

const settlementFields = [...settlementType.fields];

const INITIAL_STATE = {
  current: {
    metadata: {
      subtitle: '',
      title: '',
      authoredAt: helpers.getFormattedDate(new Date()),
      [cardinalPoints]: true,
      [companyLogo]: true,
      [clientLogo]: true,
      [scaleBar]: true,
      clientLogo: null,
    },
    mapTemplate: {
      baseLayers: [
        {
          id: 1,
          name: 'Default',
        },
      ],
      dataLayers: [
        {
          id: 1,
          name: 'Health Facilities',
          layerCategory: poiCategory,
          layerStyle: {},
        },
        { id: 2, name: 'Built-up Areas', layerCategory: stCategory, layerStyle: {} },
        { id: 3, name: 'State Boundaries', layerCategory: abCategory, layerStyle: {} },
      ],
    },
    baseLayer: {
      source: 'mapbox://styles/mapbox/basic-v9',
    },
    settlementFields,
    pageSetup: {
      size: 'A4',
      orientation: 'landscape',
    },
    saved: false,
    visibleFeatureLabels: {},
  },
  listing: [],
};

export default (state = INITIAL_STATE, action) => {
  const { data, payload, type } = action;
  switch (type) {
    case CHANGE_BASE_LAYER: {
      const baseLayer = state.current.mapTemplate.baseLayers.find(
        (b) => b.id === payload.baseLayerId,
      );
      return {
        ...state,
        current: {
          ...state.current,
          baseLayer,
        },
      };
    }
    case GET_MAP_TEMPLATE_SUCCESS: {
      const baseLayer = payload.baseLayers.length ? payload.baseLayers[0] : null;
      return {
        ...state,
        current: {
          ...state.current,
          baseLayer,
          mapTemplate: {
            ...payload,
          },
        },
      };
    }
    case SET_MAP_METADATA: {
      const metadata = data.metadata || {};
      return {
        ...state,
        current: {
          ...state.current,
          metadata: {
            ...state.current.metadata,
            ...metadata,
          },
        },
      };
    }
    case CHANGE_PAGE_SETUP: {
      return {
        ...state,
        current: {
          ...state.current,
          pageSetup: {
            ...state.current.pageSetup,
            ...data,
          },
        },
      };
    }
    case SET_TABLE_FIELD: {
      const settlementFields = helpers.getFields(
        state.current.settlementFields,
        payload.field,
        payload.action,
      );
      return {
        ...state,
        current: {
          ...state.current,
          settlementFields,
        },
      };
    }
    case SET_CLIENT_LOGO: {
      const { image: clientLogo } = data;
      return {
        ...state,
        current: {
          ...state.current,
          metadata: {
            ...state.current.metadata,
            clientLogo,
          },
        },
      };
    }
    case TOGGLE_MAP_IMAGE: {
      const { imageName, visible } = payload;
      return {
        ...state,
        current: {
          ...state.current,
          metadata: {
            ...state.current.metadata,
            [imageName]: visible,
          },
        },
      };
    }
    case GET_MAP_DOCUMENT_SUCCESS: {
      payload.baseLayer = payload.mapTemplate.baseLayers.find(
        (baseLayer) => baseLayer.id === payload.baseLayerId,
      );
      return {
        ...state,
        current: { ...payload, saved: true, settlementFields },
      };
    }
    case GET_MAP_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        listing: payload,
      };
    }
    case RESET_MAP_DOCUMENT: {
      return {
        ...state,
        current: {
          ...state.current,
          settlementFields: INITIAL_STATE.current.settlementFields,
          pageSetup: INITIAL_STATE.current.pageSetup,
          metadata: INITIAL_STATE.current.metadata,
        },
      };
    }
    default:
      return state;
  }
};
