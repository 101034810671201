import {
  GET_DATA_LAYERS_FAILURE,
  GET_DATA_LAYERS_REQUEST,
  GET_DATA_LAYERS_SUCCESS,
  GET_LOCATIONS_FAILURE,
  GET_LOCATIONS_REQUEST,
  GET_LOCATIONS_SUCCESS,
  GET_MAP_DOCUMENT_FAILURE,
  GET_MAP_DOCUMENT_REQUEST,
  GET_MAP_DOCUMENT_SUCCESS,
  GET_MAP_DOCUMENTS_FAILURE,
  GET_MAP_DOCUMENTS_REQUEST,
  GET_MAP_DOCUMENTS_SUCCESS,
  SAVE_MAP_DOCUMENT_FAILURE,
  SAVE_MAP_DOCUMENT_REQUEST,
  SAVE_MAP_DOCUMENT_SUCCESS,
} from '../types';

const INITIAL_STATE = {
  locations: false,
  dataLayerFeatures: false,
  mapDocuments: true,
  savingMapDocument: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type } = action;

  switch (type) {
    case GET_LOCATIONS_REQUEST: {
      return {
        ...state,
        locations: true,
      };
    }
    case GET_LOCATIONS_SUCCESS:
    case GET_LOCATIONS_FAILURE: {
      return {
        ...state,
        locations: false,
      };
    }
    case GET_DATA_LAYERS_REQUEST: {
      return {
        ...state,
        dataLayerFeatures: true,
      };
    }
    case GET_DATA_LAYERS_SUCCESS:
    case GET_DATA_LAYERS_FAILURE: {
      return {
        ...state,
        dataLayerFeatures: false,
      };
    }
    case GET_MAP_DOCUMENT_REQUEST: {
      return {
        ...state,
        mapDocument: true,
      };
    }
    case GET_MAP_DOCUMENT_SUCCESS:
    case GET_MAP_DOCUMENT_FAILURE: {
      return {
        ...state,
        mapDocument: false,
      };
    }
    case GET_MAP_DOCUMENTS_REQUEST: {
      return {
        ...state,
        mapDocuments: true,
      };
    }
    case GET_MAP_DOCUMENTS_SUCCESS:
    case GET_MAP_DOCUMENTS_FAILURE: {
      return {
        ...state,
        mapDocuments: false,
      };
    }
    case SAVE_MAP_DOCUMENT_REQUEST: {
      return {
        ...state,
        savingMapDocument: true,
      };
    }
    case SAVE_MAP_DOCUMENT_SUCCESS:
    case SAVE_MAP_DOCUMENT_FAILURE: {
      return {
        ...state,
        savingMapDocument: false,
      };
    }
    default:
      return state;
  }
};
