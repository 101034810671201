import { BaseLayer } from '../../types';
import { urls } from '../../utils';
import {
  CHANGE_BASE_LAYER,
  GET_DATA_LAYERS_FAILURE,
  GET_DATA_LAYERS_REQUEST,
  GET_DATA_LAYERS_SUCCESS,
  TOGGLE_DATA_LAYER,
  TOGGLE_DATA_LAYER_LABEL,
} from '../types';

export const changeBaseLayer = (baseLayerId: BaseLayer) => (dispatch) => {
  dispatch({ type: CHANGE_BASE_LAYER, payload: { baseLayerId } });
};

export const loadDataLayerFeatures =
  (
    templateId: string,
    adminLevel: string,
    adminLevelCode: string,
    dataLayerIds: Array<string> = [],
  ) =>
  (dispatch) => {
    dispatch({
      dataLayerIds,
      types: [
        GET_DATA_LAYERS_REQUEST,
        GET_DATA_LAYERS_SUCCESS,
        GET_DATA_LAYERS_FAILURE,
      ],
      promise: (client: any) =>
        client.get(
          `${urls.LAYER_FEATURES}/${templateId}/${adminLevel}/${adminLevelCode}?format=geojson`,
        ),
    });
  };

export const toggleDataLayer = (dataLayerId: string) => (dispatch) => {
  dispatch({ type: TOGGLE_DATA_LAYER, data: { dataLayerId } });
};

export const toggleLayerLabel = (dataLayerId: string) => (dispatch) => {
  dispatch({ type: TOGGLE_DATA_LAYER_LABEL, data: { dataLayerId } });
};
