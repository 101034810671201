// LOCATIONS
export const CLEAR_LOCATIONS = '@@MC/LOCAL/CLEAR_LOCATIONS';
export const LOCALIZE_MAP = '@@MC/LOCAL/LOCALIZE_MAP';
export const RESET_SELECTIONS = '@@MC/LOCAL/RESET_SELECTIONS';

export const TOGGLE_LOCATION_MODAL = '@@MC/LOCAL/TOGGLE_LOCATION_MODAL';

export const GET_LOCATIONS_REQUEST = '@@MC/API/GET_LOCATIONS_REQUEST';
export const GET_LOCATIONS_SUCCESS = '@@MC/API/GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAILURE = '@@MC/API/GET_LOCATIONS_FAILURE';

// BASE LAYERS
export const CHANGE_BASE_LAYER = '@@MC/LOCAL/CHANGE_BASE_LAYER';

// DATA LAYERS
export const TOGGLE_DATA_LAYER = '@@MC/LOCAL/TOGGLE_DATA_LAYER';
export const GET_DATA_LAYERS_REQUEST = '@@MC/API/GET_DATA_LAYERS_REQUEST';
export const GET_DATA_LAYERS_SUCCESS = '@@MC/API/GET_DATA_LAYERS_SUCCESS';
export const GET_DATA_LAYERS_FAILURE = '@@MC/API/GET_DATA_LAYERS_FAILURE';

// MAP_TEMPLATE
export const GET_MAP_TEMPLATE_REQUEST = '@@MC/API/GET_MAP_TEMPLATE_REQUEST';
export const GET_MAP_TEMPLATE_SUCCESS = '@@MC/API/GET_MAP_TEMPLATE_SUCCESS';
export const GET_MAP_TEMPLATE_FAILURE = '@@MC/API/GET_MAP_TEMPLATE_FAILURE';

// MAP_DOCUMENT
export const CHANGE_MAP_NAME = '@@MC/LOCAL/CHANGE_MAP_NAME';
export const CHANGE_CLIENT_NAME = '@@MC/LOCAL/CHANGE_CLIENT_NAME';
export const CHANGE_PAGE_SETUP = '@@MC/LOCAL/CHANGE_PAGE_SETUP';
export const SET_TABLE_FIELD = '@@MC/LOCAL/SET_TABLE_FIELD';
export const SET_CLIENT_LOGO = '@@MC/LOCAL/SET_CLIENT_LOGO';
export const TOGGLE_MAP_IMAGE = '@@MC/LOCAL/TOGGLE_MAP_IMAGE';
export const TOGGLE_DATA_LAYER_LABEL = '@@MC/LOCAL/TOGGLE_DATA_LAYER_LABEL';
export const GET_MAP_DOCUMENT_REQUEST = '@@MC/API/GET_MAP_DOCUMENT_REQUEST';
export const GET_MAP_DOCUMENT_SUCCESS = '@@MC/API/GET_MAP_DOCUMENT_SUCCESS';
export const GET_MAP_DOCUMENT_FAILURE = '@@MC/API/GET_MAP_DOCUMENT_FAILURE';
export const GET_MAP_DOCUMENTS_REQUEST = '@@MC/API/GET_MAP_DOCUMENTS_REQUEST';
export const GET_MAP_DOCUMENTS_SUCCESS = '@@MC/API/GET_MAP_DOCUMENTS_SUCCESS';
export const GET_MAP_DOCUMENTS_FAILURE = '@@MC/API/GET_MAP_DOCUMENTS_FAILURE';
export const RESET_MAP_DOCUMENT = '@@MC/LOCAL/RESET_MAP_DOCUMENT';
export const SAVE_MAP_DOCUMENT_REQUEST = '@@MC/API/SAVE_MAP_DOCUMENT_REQUEST';
export const SAVE_MAP_DOCUMENT_SUCCESS = '@@MC/API/SAVE_MAP_DOCUMENT_SUCCESS';
export const SAVE_MAP_DOCUMENT_FAILURE = '@@MC/API/SAVE_MAP_DOCUMENT_FAILURE';

// METADATA
export const SET_MAP_METADATA = '@@MC/LOCAL/SET_MAP_METADATA';
