import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MapLogo from '../../assets/images/eha-logo-blue.png';
import LegendSidebar from '../../components/LegendSidebar';
import MapContent from '../../components/MapContent';
import { Boxed, Grid, Theme } from '../../components/styled-components';
import TableSidebar from '../../components/TableSidebar';
import { FieldActions, setTableField } from '../../redux/actions/mapDocument';
import { DataLayer, DataTable, DefaultRootState, Field } from '../../types';
import { helpers } from '../../utils';
import DataColumnModal from './components/DataColumnModal';
import MapDataPane from './components/MapDataPane';
import SettlementsPage from './components/SettlementsPage';

const PreviewView = () => {
  const dispatch = useDispatch();
  const [editField, setEditField] = useState<Field>();

  const {
    location: {
      currentLocation: { fullName },
    },
    dataLayer: { dataLayerFeatures },
    mapDocument: {
      current: {
        metadata,
        mapTemplate: { dataLayers },
        settlementFields,
        pageSetup: { orientation },
      },
    },
  } = useSelector((state: DefaultRootState) => state);

  const selectedDataLayers = dataLayers.filter(
    (dataLayer: DataLayer) =>
      dataLayerFeatures[dataLayer.id] && dataLayerFeatures[dataLayer.id].checked,
  );

  const saveField = (field: Field) => {
    dispatch(setTableField({ ...field }, FieldActions.REPLACE));
    setEditField(undefined);
  };

  const dataLayerCategories = helpers.groupLayersByCategory(selectedDataLayers);
  const dataTables = helpers.getDataTables(
    dataLayerFeatures,
    dataLayerCategories,
    settlementFields,
  );
  const { showClientLogo, clientLogo } = metadata;
  const isLandscape = orientation === 'landscape';

  return (
    <>
      <Grid default="24rem auto 28rem" tablet="100%" mobile="100%">
        <LegendSidebar />

        <Boxed className="map-preview-box">
          <Boxed
            margin="0"
            pad="1.4rem 2rem"
            bgcolor={Theme.PrimaryWhite}
            className="scroll-map-box"
          >
            <div className="map-container">
              <div className="map-preview-header">
                <div>
                  <h4>
                    <b>{metadata.title || 'TITLE'}</b> |{' '}
                    {metadata.subtitle || 'SUBTITLE'}
                  </h4>
                  <p>{fullName}</p>
                </div>

                {showClientLogo && (
                  <img
                    className="client-logo"
                    src={clientLogo || MapLogo}
                    alt="Client's logo"
                  />
                )}
              </div>

              <div className="map-box">
                <Grid
                  default={isLandscape ? '75% 25%' : '100%'}
                  style={{ minHeight: '100%' }}
                >
                  <MapContent />

                  {isLandscape && <MapDataPane orientation={orientation} />}
                </Grid>
              </div>

              {!isLandscape && <MapDataPane orientation={orientation} />}
            </div>

            {dataTables.map((dataTable: DataTable, i: number) => (
              <SettlementsPage key={i} dataTable={dataTable} />
            ))}
          </Boxed>
        </Boxed>

        <TableSidebar editField={setEditField} />
      </Grid>

      <DataColumnModal
        dismiss={() => setEditField(undefined)}
        field={editField}
        saveField={saveField}
      />
    </>
  );
};

export default PreviewView;
