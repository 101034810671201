import { darken, transparentize } from 'polished';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Button, PaleButton } from './Buttons';
import { media } from './theme';

const ModalFooter = styled.div`
  background: ${(props) =>
    transparentize(0.9, darken(0.3, props.color || props.theme.PrimaryColor))};
  padding: 20px 30px;
  text-align: right;
  text-transform: uppercase;

  ${Button} {
    margin-left: 5px;
    text-transform: uppercase;
  }

  ${PaleButton} {
    margin-left: 5px;
    text-transform: uppercase;
  }
`;

const ModalContainer = styled.div`
  -webkit-font-smoothing: antialiased;
  background: ${(props) =>
    transparentize(
      props.backDropOpacity,
      darken(0.3, props.backDropColor || props.theme.PrimaryDark),
    )};
  color: ${(props) => props.theme.PrimaryFontColor};
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: ${(props) => props.position};
  top: 0;
  width: 100vw;
  z-index: 1000;

  display: none;
  ${(props) =>
    props.open &&
    css`
      display: block;
    `}

  & > div {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 100%;
    resize: vertical;
    width: 100%;
  }
`;

const ModalBody = styled.div`
  background: ${(props) => props.theme.PrimaryWhite};
  box-shadow: 0 10px 35px ${(props) => transparentize(0.9, props.theme.PrimaryDark)};
  margin: 3vh;
  position: relative;

  width: ${(props) => props.width};
  ${media.phone` width: 90%;`}

  border-top: 3px solid ${(props) =>
    props.error
      ? props.theme.PrimaryRed
      : props.information
      ? props.theme.PrimaryGreen
      : props.color || props.theme.PrimaryColor};

  ${(props) =>
    props.fluid &&
    css`
      min-height: 94vh;
      padding-bottom: 80px;
      width: 95%;

      ${ModalFooter} {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
      }
    `}

  ${(props) =>
    props.expand &&
    css`
      margin: 0;
      min-height: 100vh;
      padding-bottom: 80px;
      width: 100%;

      ${ModalFooter} {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
      }
    `}
`;

const ModalExpand = styled.div`
  border-radius: 2px;
  color: ${(props) => transparentize(0.2, props.theme.PrimaryColor)};
  cursor: pointer;
  font-size: ${(props) => props.theme.FontSize_m};
  height: 30px;
  line-height: 14px;
  padding: 8px;
  position: absolute;
  right: 45px;
  text-align: center;
  top: 5px;
  transition: ${(props) => props.theme.PrimaryTransition};
  width: 30px;

  &::after {
    content: '\\e860'; // mp-resize-full-alt-1
    font-family: ${(props) => props.theme.IconFont};

    ${(props) =>
      props.expanded &&
      css`
        content: '\\e861'; // mp-resize-small
      `}
  }

  &:hover {
    color: ${(props) => props.theme.PrimaryFontColor};
  }
`;

const ModalClose = styled.div`
  border-radius: 2px;
  color: ${(props) => transparentize(0.6, props.theme.PrimaryFontColor)};
  cursor: pointer;
  font-size: ${(props) => props.theme.FontSize_m};
  height: 30px;
  line-height: 12px;
  padding: 9px;
  position: absolute;
  right: 5px;
  text-align: center;
  top: 5px;
  transition: ${(props) => props.theme.PrimaryTransition};
  width: 30px;

  &::after {
    content: '\\e846'; // mp-cancel-1
    font-family: ${(props) => props.theme.IconFont};
  }

  &:hover {
    color: ${(props) => props.theme.PrimaryFontColor};
  }
`;

const ModalTitle = styled.div`
  font-family: ${(props) => props.theme.SecondaryFont};
  font-size: ${(props) => props.theme.FontSize_l};
  font-weight: bold;
  margin: 30px 30px 0 30px;
  padding: 0;
  position: relative;

  ${(props) =>
    props.subTitle &&
    css`
      &::before {
        content: '${(props) => props.subTitle}';
        display: block;
        font-size: ${(props) => props.theme.FontSize_standard};
        font-weight: normal;
        opacity: 0.6;
        text-transform: uppercase;
      }
    `}
`;

const ModalContent = styled.div`
  font-family: ${(props) => props.theme.PrimaryFont};
  font-size: ${(props) => props.theme.FontSize_standard};
  margin: 20px 30px 30px 30px;
`;

const Modal = (props) => {
  return (
    <ModalContainer
      backDropColor={props.backDropColor}
      backDropOpacity={props.backDropOpacity}
      position={props.position}
      open={props.open}
    >
      <div>{props.children}</div>
    </ModalContainer>
  );
};

export const ModalComponent = (props) => {
  const [expand, setExpand] = useState(false);

  return (
    <Modal
      backDropColor={props.backDropColor}
      backDropOpacity={props.backDropOpacity}
      open={props.open}
      position={props.position}
    >
      <ModalBody
        color={props.color}
        error={props.error}
        expand={expand}
        fluid={props.fluid}
        information={props.information}
        width={props.width}
      >
        {props.onClose && <ModalClose onClick={props.onClose} />}
        {props.expandable && (
          <ModalExpand expanded={expand} onClick={() => setExpand(!expand)} />
        )}
        {props.title && (
          <ModalTitle subTitle={props.subTitle}>{props.title}</ModalTitle>
        )}
        {props.children && <ModalContent>{props.children}</ModalContent>}
        {props.footer && <ModalFooter color={props.color}>{props.footer}</ModalFooter>}
      </ModalBody>
    </Modal>
  );
};

ModalComponent.propTypes = {
  backDropColor: PropTypes.string,
  backDropOpacity: PropTypes.number,
  children: PropTypes.node,
  color: PropTypes.string,
  error: PropTypes.bool,
  fluid: PropTypes.bool,
  footer: PropTypes.object,
  information: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  position: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
};

ModalComponent.defaultProps = {
  backDropOpacity: 0.7,
  position: 'fixed',
  width: '500px',
};
