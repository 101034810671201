import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { changeBaseLayer } from '../../redux/actions/layers';
import { toggleModal } from '../../redux/actions/location';
import { DefaultRootState } from '../../types';
import { Boxed, Grid, Input, SimpleSelect } from '../styled-components';

const LocalizationBar = () => {
  const dispatch = useDispatch();
  const {
    location: {
      showModal,
      currentLocation: { fullName },
    },
    mapDocument: {
      current: {
        id: mapDocumentId,
        baseLayer,
        mapTemplate: { baseLayers },
      },
    },
  } = useSelector((state: DefaultRootState) => state);

  useEffect(() => {
    if (mapDocumentId) {
      dispatch(toggleModal(false));
    }
  }, [dispatch, mapDocumentId]);

  const toOptions = (baseLayers) => {
    return baseLayers.map(({ id, name }) => ({ label: name, value: id }));
  };

  const toggle = () => {
    if (!mapDocumentId) {
      dispatch(toggleModal(!showModal));
    }
  };

  return (
    <Boxed>
      <Grid pad="5px" default="50% auto" tablet="100%" mobile="100%">
        <Input
          forminput
          onClick={() => toggle()}
          readOnly
          required
          type="location"
          value={fullName || ''}
        />
        <SimpleSelect
          forminput
          onChange={({ value }) => dispatch(changeBaseLayer(value))}
          options={toOptions(baseLayers)}
          placeholder="SELECT BASE LAYER"
          required
          value={{ label: baseLayer.name, value: baseLayer.id }}
        />
      </Grid>
    </Boxed>
  );
};
export default LocalizationBar;
