import React from 'react';

import MapTemplateBar from '../../components/Bars/MapTemplateBar';
import LocalizationBar from '../../components/LocalizationBar';
import { Boxed, Grid } from '../../components/styled-components';

const ExplorerBarStrip = () => {
  return (
    <Boxed outline className="tab-bar">
      <Grid pad="10px" default="24rem 1fr 45%" tablet="24rem 1fr 50%" mobile="100%">
        <MapTemplateBar />
        <Boxed />
        <LocalizationBar />
      </Grid>
    </Boxed>
  );
};

export default ExplorerBarStrip;
