import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Boxed } from '../../components/styled-components';
import { toggleDataLayer, toggleLayerLabel } from '../../redux/actions/layers';
import { DefaultRootState, LayerCategory as LayerCategoryType } from '../../types';
import { helpers } from '../../utils';
import LayerCategory from './LayerCategory';

const LayerPane = () => {
  const {
    dataLayer: { dataLayerFeatures: dataLayersContents },
    mapDocument: {
      current: {
        id: mapDocumentId,
        mapTemplate: { dataLayers },
      },
    },
    loader: { loadingFeatures },
  } = useSelector((state: DefaultRootState) => state);
  const dispatch = useDispatch();

  const layerCategories = helpers.groupLayersByCategory(dataLayers);

  return (
    <Boxed className="side-bar-dropdowns">
      {layerCategories.map((category: LayerCategoryType) => (
        <LayerCategory
          key={helpers.buildKey(category.name)}
          name={category.name}
          subtitle={category.subtitle}
          description={category.description}
          dataLayers={category.dataLayers}
          dataLayersContents={dataLayersContents}
          loadingFeatures={loadingFeatures}
          onToggleDataLayer={(dataLayerId) => dispatch(toggleDataLayer(dataLayerId))}
          onToggleLayerLabel={(dataLayerId) => dispatch(toggleLayerLabel(dataLayerId))}
          editable={!mapDocumentId}
        />
      ))}
    </Boxed>
  );
};

export default LayerPane;
