import { Feature, FeatureCollection, Properties } from '@turf/helpers';

import { FieldActions } from '../redux/actions/mapDocument';
import {
  CheckedFeatureCollection,
  DataLayer,
  DataLayerParams,
  DataTable,
  DictionaryOf,
  Field,
  LayerCategory,
  MapBoxStyle,
} from '../types';
import constants from './constants';

/**
 * Returns a normalized string suitable for use as a unique key
 * @param value the value to build key from
 */
const buildKey = (value: string) => (value || '').toLowerCase().split(' ').join('-');

/**
 * Returns list of data layers that have been checked
 * @param layers array of data layers to process
 * @param dataLayersFeatures array of data layers features to process
 */
const getCheckedLayers = (
  layers: DataLayer[],
  dataLayersFeatures: DictionaryOf<CheckedFeatureCollection>,
) => layers.filter((layer) => (dataLayersFeatures[layer.id] || {}).checked);

/**
 * Groups array of data layers by LayerGroup they belong to
 * @param layers array of data layers to process
 */
const groupLayersByCategory = (layers: DataLayer[]): LayerCategory[] => {
  const layerCategories: DictionaryOf<LayerCategory> = {};
  layers.forEach((layer) => {
    if (layerCategories[layer.layerCategory.id]) {
      layerCategories[layer.layerCategory.id].dataLayers.push(layer);
    } else {
      layerCategories[layer.layerCategory.id] = {
        ...layer.layerCategory,
        dataLayers: [layer],
      };
    }
  });
  return Object.values(layerCategories);
};

/**
 * Returns an array of arrays of properties located within the features array and chucks it using the chunkSize
 * @param features array of features contaning properties
 * @param chunkSize size to chunk the array by which to chunk the Properties
 */
const chunkFeaturesProperties = (
  features: Feature[],
  chunkSize = 40,
): Properties[][] => {
  const featuresArray = [...features]
    .sort((a, b) =>
      a &&
      b &&
      a.properties &&
      b.properties &&
      a.properties.name.toLowerCase() > b.properties.name.toLowerCase()
        ? 1
        : -1,
    )
    .map((feature, i) => ({ sn: i + 1, ...feature.properties }));
  const chunkedFeatures: Properties[][] = [];
  for (let i = 0; i < featuresArray.length; i += chunkSize) {
    chunkedFeatures.push(featuresArray.slice(i, i + chunkSize));
  }
  return chunkedFeatures;
};

/**
 * Returns an array of arrays DataTables to display  with the Map
 * @param dataLayerFeatures - Dictionary constaning the features indexed by the respective DataLayer
 * @param layerCategories - the Layer Categories by which to group the data tables
 * @param settlementFields - Fields to show for the settlementsTable
 */
const getDataTables = (
  dataLayerFeatures: DictionaryOf<any>,
  layerCategories: LayerCategory[],
  settlementFields: Field[],
): DataTable[] => {
  const dataLayerLegends = {};
  return layerCategories.map((layerCategory: LayerCategory) => {
    let categoryTableRows: Array<any> = [];
    layerCategory.dataLayers.forEach(
      (dataLayer: DataLayer) =>
        (categoryTableRows = [
          ...categoryTableRows,
          ...dataLayerFeatures[dataLayer.id].features,
        ]),
    );
    if (layerCategory.name === constants.layerCategoriesConfig.settlementType.name) {
      layerCategory.fields = settlementFields;
    }
    const paginatedTables = chunkFeaturesProperties(categoryTableRows);
    layerCategory.dataLayers.forEach((dataLayer: DataLayer) => {
      dataLayerLegends[dataLayer.filterField] = false;
    });
    return {
      id: layerCategory.id,
      name: layerCategory.name,
      fields: layerCategory.fields,
      display: !!layerCategory.fields,
      paginatedTables,
    };
  });
};

const getLayerLocationProperties = (
  featureCollection: CheckedFeatureCollection,
  locationField: string,
): Properties[] => {
  const features = (featureCollection || {}).features || [];
  const properties: Properties[] = (features || [])
    .map((feature: Feature) => feature.properties || {})
    .filter((properties) => properties && properties[locationField]);
  return properties;
};

/**
 * Returns a Dictionary containing the params to process the map for each category
 * @param dataLayers the DataLayers to process
 * @param dataLayerFeatures Dictionary contaning the features indexed by the respective DataLayer
 * @param settlementFields Array of fields for the settlement features properties
 */
const getDataLayerParams = (
  dataLayers: DataLayer[],
  dataLayerFeatures: DictionaryOf<CheckedFeatureCollection>,
  settlementFields: Field[],
): DataLayerParams[] => {
  const selectedDataLayers = getCheckedLayers(dataLayers, dataLayerFeatures);
  const isSettlementLayer = (layer: DataLayer) =>
    layer.layerCategory.name === constants.layerCategoriesConfig.settlementType.name;
  const dataLayersParams: DataLayerParams[] = selectedDataLayers.map(
    (dataLayer: DataLayer) => ({
      id: dataLayer.id,
      showFeatureLabel: dataLayerFeatures[dataLayer.id].showLabel,
      styleId: dataLayer.layerStyle.id,
      fields: isSettlementLayer(dataLayer)
        ? settlementFields.filter((obj) => obj.selected || obj.default)
        : [],
    }),
  );
  return dataLayersParams;
};

/**
 * Returns a string representation of the date in a specific (yyyy-MM-dd) format
 * @param date the date to format
 */
const getFormattedDate = (date: Date) => {
  return date.toISOString().substring(0, 10);
};

/**
 * Returns mapbox styles
 * @param layerStyle - MapBox Layers styles definitions
 */
const getStyles = (layerStyle: DictionaryOf<MapBoxStyle>): Array<MapBoxStyle> => {
  const { type, styles } = layerStyle;
  return Object.keys(styles).map((styleKey) => {
    return {
      ...styles[styleKey],
      type: styles[styleKey].type ? styles[styleKey].type : type,
    };
  });
};

const getLegendStyle = (dataLayer): DictionaryOf<string> | void => {
  const POLYGON = 'polygon';
  const {
    layerStyle: {
      type,
      styles: { fill, line },
    },
  } = dataLayer;
  if (type !== POLYGON && type !== 'line') {
    return;
  }
  const color = fill && fill.color;
  const borderStyle = type === POLYGON ? 'dashed' : 'solid';
  const borderColor = line && line.color;
  const height = type === POLYGON ? 7 : 2;
  return {
    height: `${height}px`,
    background: color,
    border: `1px ${borderStyle} ${borderColor}`,
  };
};

/**
 * Returns FeatureCollection from the Properties list of the FeatureCollection
 * @param geometryKey - attribute of the properties  of the FeatureCollection that has the geometry coordinate,
 * @param checkedFeatureCollection - FeatureCollection of the respective Layer
 */
const getFeatureCollectionFromProperties = (
  geometryKey,
  checkedFeatureCollection: CheckedFeatureCollection,
): FeatureCollection => {
  const propertiesArray: Properties = getLayerLocationProperties(
    checkedFeatureCollection,
    geometryKey,
  );
  return {
    type: 'FeatureCollection',
    features: [
      ...propertiesArray.map((properties) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: properties[geometryKey]['coordinates'],
        },
        properties,
      })),
    ],
  };
};

/**
 * Returns list of MapBoxStyles used to create the Map Layers
 * @param dataLayerFeatures - featureCollections indexed  by DataLayerId
 * @param dataLayerStyles - array of MapBoxStyles indexed by DataLayerId
 */
const getMapboxStyles = (
  checkedLayers: DataLayer[],
  dataLayerFeatures: DictionaryOf<CheckedFeatureCollection>,
  dataLayerStyles: DictionaryOf<MapBoxStyle[]>,
): MapBoxStyle[] => {
  const mapBoxStyles: MapBoxStyle[] = [];
  checkedLayers.forEach((layer) => {
    const styles: MapBoxStyle[] = dataLayerStyles[layer.id] || [];
    styles.forEach((style, i) => {
      style.id = layer.id + `${i}`;
      style.featureCollection =
        style.datasource === 'properties'
          ? getFeatureCollectionFromProperties(
              'location_point',
              dataLayerFeatures[layer.id],
            )
          : dataLayerFeatures[layer.id];
      mapBoxStyles.push(style);
    });
  });
  return mapBoxStyles.sort((a, b) => a.sortIndex | (0 - b.sortIndex) | 0);
};

/**
 * @returns List of modified dataLayer styles
 * @param oldDataLayerStyles -  current dataLayer styles
 * @param dataLayerId -  id of dataLayer  to modify the respective style
 * @param styleName - the specific style definition to modify
 * @param visible - visibility state of the layer
 */
const toggleVisibility = (
  oldDataLayerStyles: DictionaryOf<Array<MapBoxStyle>>,
  dataLayerId: string,
  styleName: string,
  visible: boolean,
): DictionaryOf<Array<MapBoxStyle>> => {
  const dataLayerStyles = { ...oldDataLayerStyles };
  Object.keys(dataLayerStyles).forEach((layerId) => {
    if (dataLayerId === layerId) {
      (dataLayerStyles[layerId] || []).forEach((mapBoxStyle: MapBoxStyle) => {
        if (mapBoxStyle.name === styleName) {
          const visibility = visible ? 'visible' : 'none';
          mapBoxStyle.layout = { ...mapBoxStyle.layout, visibility };
        }
      });
    }
  });
  return dataLayerStyles;
};

/**
 *
 * @param fields Existing fields
 * @param field Field to act upon
 * @param action specified action (RESORT or REPLACE)
 */
const getFields = (fields: Field[], field: Field, action: string) => {
  let newFields: Field[] = [];
  if (action === FieldActions.RESORT) {
    const setField = fields.find(({ id }) => field.id === id);
    const resortField = fields.find(
      ({ id, index }) => field.index === index && (setField || {}).id !== id,
    );
    const fieldIndex = (setField || {}).index || 0;
    const resortIndex = (resortField || {}).index || 0;
    const otherFields = fields.filter(
      ({ id }) => id !== (setField || {}).id && id !== (resortField || {}).id,
    );
    if (setField !== undefined && resortField === undefined) {
      newFields = [field, ...otherFields];
    } else if (field !== undefined && resortField !== undefined) {
      newFields = [
        { ...field, index: resortIndex },
        { ...resortField, index: fieldIndex },
        ...otherFields,
      ];
    } else {
      newFields = [...otherFields];
    }
  } else if (action === FieldActions.REPLACE) {
    const otherFields = fields.filter(({ id }) => id !== field.id);
    newFields = [...otherFields, field];
  }
  return newFields.sort((a, b) => a.index - b.index);
};

const file2Base64 = async (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const omit = (obj, keys: string | string[]) => {
  if (!Array.isArray(keys)) keys = [keys];
  let result = obj;
  keys.forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [key]: _omitted, ...rest } = result;
    result = rest;
  });
  return result;
};

export default {
  buildKey,
  configureStyles: getStyles,
  file2Base64,
  getCheckedLayers,
  getDataLayerParams,
  getDataTables,
  getFields,
  getFormattedDate,
  getLegendStyle,
  getMapboxStyles,
  groupLayersByCategory,
  omit,
  toggleVisibility,
};
