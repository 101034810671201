import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const Pretty = styled.div`
  display: inline-block;
  line-height: 1;
  margin-right: 1em;
  position: relative;
  white-space: nowrap;

  & * {
    box-sizing: border-box;
  }

  & input:not([type='checkbox']):not([type='radio']) {
    display: none;
  }

  & input {
    cursor: pointer;
    height: 100%;
    left: 0;
    margin: 0;
    min-width: 1em;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  & .state label {
    display: inline-block;
    font-weight: 400;
    margin: 0;
    min-width: calc(1em + 2px);
    position: initial;
    text-indent: 1.5em;
  }

  & .state label::after,
  & .state label::before {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: ${(props) => props.theme.PrimaryRadius};
    box-sizing: border-box;
    content: '';
    display: block;
    height: calc(1em + 2px);
    left: 0;
    position: absolute;
    top: calc(50% - 0.5em - 1px);
    width: calc(1em + 2px);
    z-index: 0;
  }

  & .state label::before {
    border-color: ${(props) => props.theme.PrimaryColor_light_40};
  }

  & .state.p-is-hover,
  & .state.p-is-indeterminate {
    display: none;
  }

  &.p-default.p-fill .state label::after {
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  &.p-default .state label::after {
    -ms-transform: scale(0.6);
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }

  &.p-default input:checked ~ .state label::after {
    background-color: ${(props) => props.theme.PrimaryColor};
  }

  &.p-default.p-thick .state label::after,
  &.p-default.p-thick .state label::before {
    border-width: calc(1em / 7);
  }

  &.p-default.p-thick .state label::after {
    -ms-transform: scale(0.4);
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }

  &.p-icon .state .icon {
    border: 1px solid transparent;
    font-size: ${(props) => props.theme.FontSize_s};
    height: calc(1em + 2px);
    left: 0;
    line-height: 1em;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: calc(50% - 0.5em - 1px);
    width: calc(1em + 2px);
    z-index: 1;
  }

  &.p-icon .state .icon::before {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -ms-flex: 1;
    -webkit-box-align: center;
    -webkit-box-flex: 1;
    -webkit-box-pack: center;
    align-items: center;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
    line-height: 1;
    margin: 0;
    text-align: center;
    width: 100%;
  }

  &.p-icon input:checked ~ .state .icon {
    opacity: 1;
  }

  &.p-icon input:checked ~ .state label::before {
    border-color: ${(props) => props.theme.PrimaryColor};
  }

  &.p-svg .state .svg {
    border: 1px solid transparent;
    font-size: ${(props) => props.theme.FontSize_standard};
    height: calc(1em + 2px);
    left: 0;
    line-height: normal;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: calc((0% - (100% - 1em)) - 8%);
    width: calc(1em + 2px);
    z-index: 1;
  }

  &.p-switch input {
    min-width: 2em;
  }

  &.p-switch .state {
    position: relative;
  }

  &.p-switch .state::before {
    border: 1px solid ${(props) => props.theme.PrimaryColor};
    border-radius: 60px;
    box-sizing: unset;
    content: '';
    height: calc(1em + 2px);
    left: -1px;
    position: absolute;
    top: calc((0% - (100% - 0.98em)) - 16%);
    transition: all 0.5s ease;
    width: calc(2em + 2px);
    z-index: 0;
  }

  &.p-switch .state label {
    text-indent: 2.5em;
  }

  &.p-switch .state label::after,
  &.p-switch .state label::before {
    -ms-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    border-color: transparent;
    border-radius: 100%;
    left: 0;
    transform: scale(0.8);
    transition: all 0.5s ease;
  }

  &.p-switch .state label::after {
    background-color: ${(props) => props.theme.PrimaryColor};
  }

  &.p-switch input:checked ~ .state::before {
    border-color: ${(props) => props.theme.PrimaryColor};
  }

  &.p-switch input:checked ~ .state label::before {
    opacity: 0;
  }

  &.p-switch input:checked ~ .state label::after {
    background-color: ${(props) => props.theme.PrimaryColor};
    left: 1em;
  }

  &.p-switch.p-fill input:checked ~ .state::before {
    background-color: ${(props) => props.theme.PrimaryColor};
    border-color: ${(props) => props.theme.PrimaryColor};
  }

  &.p-switch.p-fill input:checked ~ .state label::before {
    opacity: 0;
  }

  &.p-switch.p-fill input:checked ~ .state label::after {
    background-color: ${(props) => props.theme.PrimaryWhite};
    left: 1em;
  }

  &.p-switch.p-slim .state::before {
    background: ${(props) => props.theme.PrimaryColor};
    height: 0.1em;
    top: calc(50% - 0.1em);
  }

  &.p-switch.p-slim input:checked ~ .state::before {
    background-color: ${(props) => props.theme.PrimaryColor};
    border-color: ${(props) => props.theme.PrimaryColor};
  }

  &.p-has-hover input:hover ~ .state:not(.p-is-hover) {
    display: none;
  }

  &.p-has-hover input:hover ~ .state.p-is-hover {
    display: block;
  }

  &.p-has-hover input:hover ~ .state.p-is-hover .icon {
    display: block;
  }

  &.p-has-focus input:focus ~ .state label::before {
    box-shadow: 0 0 3px 0 ${(props) => props.theme.PrimaryColor};
  }

  &.p-has-indeterminate
    input[type='checkbox']:indeterminate
    ~ .state:not(.p-is-indeterminate) {
    display: none;
  }

  &.p-has-indeterminate
    input[type='checkbox']:indeterminate
    ~ .state.p-is-indeterminate {
    display: block;
  }

  &.p-has-indeterminate
    input[type='checkbox']:indeterminate
    ~ .state.p-is-indeterminate
    .icon {
    display: block;
    opacity: 1;
  }

  &.p-toggle .state.p-on {
    display: none;
    opacity: 0;
  }

  &.p-toggle .state .icon,
  &.p-toggle .state .svg,
  &.p-toggle .state img,
  &.p-toggle .state.p-off {
    display: inherit;
    opacity: 1;
  }

  &.p-toggle .state.p-off .icon {
    color: ${(props) => props.theme.PrimaryColor};
  }

  &.p-toggle input:checked ~ .state.p-on {
    display: inherit;
    opacity: 1;
  }

  &.p-toggle input:checked ~ .state.p-off {
    display: none;
    opacity: 0;
  }

  &.p-plain input:checked ~ .state label::before,
  &.p-plain.p-toggle .state label::before {
    content: none;
  }

  &.p-plain.p-plain .icon {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  &.p-round .state label::after,
  &.p-round .state label::before {
    border-radius: 100%;
  }

  &.p-round.p-icon .state .icon {
    border-radius: 100%;
    overflow: hidden;
  }

  &.p-round.p-icon .state .icon::before {
    -ms-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  &.p-curve .state label::after,
  &.p-curve .state label::before {
    border-radius: 2px;
  }

  &.p-smooth .icon,
  &.p-smooth .svg,
  &.p-smooth label::after,
  &.p-smooth label::before {
    transition: all 0.5s ease;
  }

  &.p-smooth input:checked + .state label::after {
    transition: all 0.3s ease;
  }

  &.p-smooth input:checked + .state .icon,
  &.p-smooth input:checked + .state .svg,
  &.p-smooth input:checked + .state img {
    -webkit-animation: zoom 0.2s ease;
    animation: zoom 0.2s ease;
  }

  &.p-smooth.p-default input:checked + .state label::after {
    -webkit-animation: zoom 0.2s ease;
    animation: zoom 0.2s ease;
  }

  &.p-smooth.p-plain input:checked + .state label::before {
    -ms-transform: scale(0);
    -webkit-transform: scale(0);
    content: '';
    transform: scale(0);
    transition: all 0.5s ease;
  }

  & input[disabled] {
    cursor: not-allowed;
    display: none;
  }

  & input[disabled] ~ * {
    opacity: 0.5;
  }

  &.p-locked input {
    display: none;
    cursor: not-allowed;
  }

  & input:checked ~ .state.p-primary label::after,
  &.p-toggle .state.p-primary label::after {
    background-color: ${(props) => props.theme.PrimaryColor};
  }

  & input:checked ~ .state.p-primary .icon,
  & input:checked ~ .state.p-primary .svg,
  &.p-toggle .state.p-primary .icon,
  &.p-toggle .state.p-primary .svg {
    color: ${(props) => props.theme.PrimaryWhite};
    stroke: ${(props) => props.theme.PrimaryWhite};
  }

  & input:checked ~ .state.p-primary-o label::before,
  &.p-toggle .state.p-primary-o label::before {
    border-color: ${(props) => props.theme.PrimaryColor};
  }

  & input:checked ~ .state.p-primary-o label::after,
  &.p-toggle .state.p-primary-o label::after {
    background-color: ${(props) => props.theme.PrimaryColor};
  }

  & input:checked ~ .state.p-primary-o .icon {
    color: ${(props) => props.theme.PrimaryColor};
    stroke: ${(props) => props.theme.PrimaryColor};
  }

  &.p-default:not(.p-fill) input:checked ~ .state.p-primary-o label::after {
    background-color: ${(props) => props.theme.PrimaryColor};
  }

  &.p-switch input:checked ~ .state.p-primary::before {
    border-color: ${(props) => props.theme.PrimaryColor};
  }

  &.p-switch.p-fill input:checked ~ .state.p-primary::before {
    background-color: ${(props) => props.theme.PrimaryColor};
  }

  &.p-switch.p-slim input:checked ~ .state.p-primary::before {
    border-color: ${(props) => props.theme.PrimaryColor};
    background-color: ${(props) => props.theme.PrimaryColor};
  }

  &.large i,
  &.large .img,
  &.large .svg,
  &.large label::after,
  &.large label::before {
    font-size: ${(props) => props.theme.FontSize_xl};
    top: calc((0% - (100% - 0.5em)) - 35%);
  }

  &.large label {
    text-indent: 2.5em;
  }

  &.large i::before,
  &.large i::after {
    font-size: ${(props) => props.theme.FontSize_l};
    padding-left: 0.25em;
  }

  &.large .toggle::before,
  &.large .toggle::after {
    font-size: ${(props) => props.theme.FontSize_xl};
    top: calc((0% - (100% - 0.35em)) - 15%);
  }

  &.large .toggle label {
    text-indent: 5em;
  }
`;

const RadioCheckWrapper = styled.div`
  display: inline-block;
  width: 100%;
  font-size: ${(props) => props.theme.PrimaryFontSize};
  position: relative;
  transition: ${(props) => props.theme.PrimaryTransition};

  ${(props) =>
    props.forminput &&
    css`
      margin-bottom: 15px;
      margin-top: 10px;
    `}

  & .state label::before {
    border-color: ${(props) => props.theme.PrimaryColor_light_40};
  }

  & .p-default input:checked ~ .state label::after {
    background-color: ${(props) => props.theme.PrimaryColor};
  }
`;

export const SimpleCheckbox = (props) => {
  const { icon } = props;
  const isCheckbox = props.type === 'checkbox';
  const isRadio = props.type === 'radio';

  let prettyClassName = 'pretty';
  prettyClassName = `${prettyClassName} ${props.large ? 'large' : ''}`;
  switch (props.type) {
    case 'checkbox':
      prettyClassName = `${prettyClassName} p-icon p-smooth`;
      break;

    case 'radio':
      prettyClassName = `${prettyClassName} p-default p-round p-smooth`;
      break;

    case 'switch':
      prettyClassName = `${prettyClassName} p-switch p-fill`;
      break;
  }

  let className = 'state';
  switch (props.type) {
    case 'checkbox':
      className = `${className} p-primary`;
      break;
    case 'switch':
      className = `${className} toggle p-primary`;
      break;
  }

  return (
    <RadioCheckWrapper className="simple-checkbox">
      <Pretty className={prettyClassName} disabled={props.disabled}>
        <input {...props} type={isRadio ? 'radio' : 'checkbox'} />
        <div className={className}>
          {isCheckbox && <i className={icon || 'icon mp-ok-circle'} />}
          <label>
            {props.label} {props.badge || ''}
          </label>
        </div>
      </Pretty>
    </RadioCheckWrapper>
  );
};

SimpleCheckbox.propTypes = {
  badge: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  checked: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.string,
  large: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string.isRequired,
};

SimpleCheckbox.defaultProps = {
  badge: '',
  checked: '',
  icon: 'icon mp-ok-circle',
  label: '',
};
