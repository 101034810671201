import React from 'react';

import { Boxed, Button, H4, Label } from '../styled-components';

const MapTemplateBar = () => (
  <Boxed pad="10px 0 15px" className="template-t-box">
    <Button className="home-btn">
      <i className="mp-home" />
    </Button>

    <Boxed pad="0 10px">
      <Label margin="5px 0 0">Template Type</Label>
      <H4>Settlement Cluster Map</H4>
    </Boxed>
  </Boxed>
);

export default MapTemplateBar;
