import { darken, lighten } from 'polished';
import { css } from 'styled-components';

const PrimaryTheme = '#0090fc';

export const Theme = {
  PrimaryColor: PrimaryTheme,
  SecondaryColor: lighten(0.6, PrimaryTheme),
  PrimaryDark: '#587387',
  PrimaryWhite: '#fff',

  PrimaryBackground: '#f4f9fc',
  PrimaryGreen: '#63cd92',
  PrimaryRed: '#ea7373',
  PrimaryOrange: '#e8a835',

  PrimaryLineHeight: '1.6em',
  PrimaryFontColor: '#4a4a4a',
  PrimaryBorderColor: '#a7b6c7',

  PrimaryFont: "'Open Sans', sans-serif",
  SecondaryFont: 'Lato, sans-serif',
  IconFont: 'map-composer',

  PrimaryFontSize: '12px',
  FontSize_xs: '.8rem',
  FontSize_s: '.86rem',
  FontSize_standard: '1rem',
  FontSize_m: '1.1rem',
  FontSize_l: '1.6rem',
  FontSize_xl: '2rem',
  FontSize_xxl: '3rem',

  PrimaryColor_light_80: lighten(0.4, PrimaryTheme),
  PrimaryColor_light_60: lighten(0.3, PrimaryTheme),
  PrimaryColor_light_40: lighten(0.2, PrimaryTheme),
  PrimaryColor_light_10: lighten(0.1, PrimaryTheme),
  PrimaryColor_dark_10: darken(0.1, PrimaryTheme),

  PrimaryGrey: '#62707b',
  PrimaryGreyDark: '#4a4a4a',
  PrimaryGreyMid: '#a4b2bd',
  PrimaryGreyLight: '#eff4f8',

  PrimaryRadius: '3px',
  SecondaryRadius: '5px',

  PrimaryTransition: 'all 0.3s ease-out',

  TopBarHeight: '6rem',
};

type Sizes = {
  giant: number;
  desktop: number;
  tablet: number;
  phone: number;
};

const sizes: Sizes = {
  giant: 1170,
  desktop: 992,
  tablet: 768,
  phone: 660,
};

type Medias = {
  giant: css;
  desktop: css;
  tablet: css;
  phone: css;
};

export const media: Medias = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {}) as Medias;
