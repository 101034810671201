import { Dictionary, DictionaryOf, Field, MapDocumentParam } from '../../types';
import { urls } from '../../utils';
import {
  CHANGE_PAGE_SETUP,
  GET_MAP_DOCUMENT_FAILURE,
  GET_MAP_DOCUMENT_REQUEST,
  GET_MAP_DOCUMENT_SUCCESS,
  GET_MAP_DOCUMENTS_FAILURE,
  GET_MAP_DOCUMENTS_REQUEST,
  GET_MAP_DOCUMENTS_SUCCESS,
  RESET_MAP_DOCUMENT,
  SAVE_MAP_DOCUMENT_FAILURE,
  SAVE_MAP_DOCUMENT_REQUEST,
  SAVE_MAP_DOCUMENT_SUCCESS,
  SET_CLIENT_LOGO,
  SET_MAP_METADATA,
  SET_TABLE_FIELD,
  TOGGLE_MAP_IMAGE,
} from '../types';
import { loadDataLayerFeatures } from './layers';

export const FieldActions = {
  REPLACE: 'REPLACE',
  RESORT: 'RESORT',
};

export const setMapMetadata = (metadata: Dictionary) => (dispatch) => {
  dispatch({ type: SET_MAP_METADATA, data: { metadata } });
};

export const setClientLogo = (image) => (dispatch) => {
  dispatch({ type: SET_CLIENT_LOGO, data: { image } });
};

export const setTableField = (field: Field, action: string) => (dispatch) => {
  dispatch({ type: SET_TABLE_FIELD, payload: { field, action } });
};

export const toggleMetadata = (imageName: string, visible: boolean) => (dispatch) => {
  dispatch({ type: TOGGLE_MAP_IMAGE, payload: { imageName, visible } });
};

export const changePageSetup = (pageSetup: DictionaryOf<string>) => (dispatch) => {
  dispatch({ type: CHANGE_PAGE_SETUP, data: pageSetup });
};

export const getDocument = (mapDocumentId: string) => (dispatch, getState) => {
  dispatch({
    types: [
      GET_MAP_DOCUMENT_REQUEST,
      GET_MAP_DOCUMENT_SUCCESS,
      GET_MAP_DOCUMENT_FAILURE,
    ],
    promise: (client: any) => client.get(`${urls.MAP_DOCUMENT}/${mapDocumentId}`),
  });
  const { mapDocument } = getState();
  dispatch(loadDataLayerFeatures(mapDocument, '', ''));
};

export const getDocuments = () => (dispatch) => {
  dispatch({
    types: [
      GET_MAP_DOCUMENTS_REQUEST,
      GET_MAP_DOCUMENTS_SUCCESS,
      GET_MAP_DOCUMENTS_FAILURE,
    ],
    promise: (client: any) => client.get(urls.MAP_DOCUMENT),
  });
};

export const saveDocument = (saveMapParam: MapDocumentParam) => (dispatch) => {
  dispatch({
    types: [
      SAVE_MAP_DOCUMENT_REQUEST,
      SAVE_MAP_DOCUMENT_SUCCESS,
      SAVE_MAP_DOCUMENT_FAILURE,
    ],
    promise: (client: any) => client.post(urls.SAVE_MAP, saveMapParam),
  });
};

export const resetDocument = () => (dispatch) => {
  dispatch({ type: RESET_MAP_DOCUMENT, data: true });
};
