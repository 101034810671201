import React from 'react';
import { useSelector } from 'react-redux';

import CompassImg from '../../../assets/images/compass.png';
import ehaLogo from '../../../assets/images/eha-icon-blue.png';
import { DataLayer, DefaultRootState } from '../../../types';
import { constants, helpers } from '../../../utils';
import LegendList from './LegendList';

const MapLegendLandscape = (props) => {
  const {
    dataLayer: { dataLayerFeatures },
    mapDocument: {
      current: {
        mapTemplate: { dataLayers },
        metadata: { showCardinalPoints, showCompanyLogo },
      },
    },
  } = useSelector((state: DefaultRootState) => state);

  const checkedLayers: DataLayer[] = helpers.getCheckedLayers(
    dataLayers,
    dataLayerFeatures,
  );

  return (
    <div className={`legend-box legend-box-${props.orientation.toLowerCase()}`}>
      <LegendList name="" dataLayers={checkedLayers} />

      <div className="map-info">
        <div className="map-text">
          <p>
            <span>{constants.citation}</span>
            <span>{constants.disclaimer}</span>
          </p>
        </div>

        <div className="map-logo">
          {showCardinalPoints && (
            <img src={CompassImg} alt="Compass" className="map-icon-img" />
          )}
          {showCompanyLogo && (
            <img src={ehaLogo} alt="eHealth" className="map-icon-img" />
          )}
        </div>
      </div>
    </div>
  );
};

export default MapLegendLandscape;
