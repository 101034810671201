import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HealthChecker from './components/HealthChecker';
import { Panel } from './components/styled-components';
import Composer from './containers/Composer';

function App() {
  return (
    <div className="app-wrapper">
      <Panel>
        <Switch>
          <Route exact path="/health" component={HealthChecker} />

          <Route exact path="/" component={Composer} />
        </Switch>
      </Panel>
    </div>
  );
}

export default App;
