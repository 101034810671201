import React, { useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch } from 'react-redux';

import { FieldActions, setTableField } from '../../redux/actions/mapDocument';
import { Field } from '../../types';
import { Boxed, Label, P } from '../styled-components';
import ColumnData from './ColumnData';

const ColumnDataList = ({ editField, fields }) => {
  const dispatch = useDispatch();
  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      dispatch(
        setTableField({ ...fields[dragIndex], index: hoverIndex }, FieldActions.RESORT),
      );
    },
    [fields, dispatch],
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Boxed pad="1.4rem 2rem">
        <P>Configure Table Information and Data Columns</P>
        <Label margin="0.5rem 0">Data Column Header</Label>
        {(fields || [])
          .filter((field) => field.default || field.selected)
          .map((field: Field, i) => (
            <ColumnData
              key={i}
              field={field}
              editField={editField}
              moveCard={moveCard}
            />
          ))}
        <P size="0.9rem">
          <i className="mp-info" /> Note: Drag to re-arrange table column header above.
        </P>
      </Boxed>
    </DndProvider>
  );
};

export default ColumnDataList;
