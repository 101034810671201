import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setClientLogo, setMapMetadata } from '../../redux/actions/mapDocument';
import { DefaultRootState } from '../../types';
import { helpers } from '../../utils';
import { Boxed, DatePicker, Grid, Input } from '../styled-components';

const MetadataBar = () => {
  const dispatch = useDispatch();
  const {
    mapDocument: {
      current: { id: mapDocumentId, metadata },
    },
  } = useSelector((state: DefaultRootState) => state);

  const onDayChange = (date) => {
    dispatch(setMapMetadata({ authoredAt: helpers.getFormattedDate(date) }));
  };

  const onImageChange = ({ target: { files } }) => {
    if (files && files[0]) {
      return helpers
        .file2Base64(files[0])
        .then((base64Image) => dispatch(setClientLogo(base64Image)));
    }
  };

  return (
    <Boxed>
      <Grid pad="10px" default="auto auto auto auto" tablet="100%" mobile="100%">
        <Input
          forminput
          required
          type="text"
          placeholder="TITLE"
          value={metadata.title || ''}
          onChange={(evt) => dispatch(setMapMetadata({ title: evt.target.value }))}
          disabled={!!mapDocumentId}
        />

        <Input
          required
          forminput
          type="text"
          placeholder="SUBTITLE"
          value={metadata.subtitle || ''}
          onChange={(evt) => dispatch(setMapMetadata({ subtitle: evt.target.value }))}
          disabled={!!mapDocumentId}
        />

        <Input className="file-upload" forminput type="file" onChange={onImageChange} />

        <DatePicker
          icon
          horizontalLayout
          calendarProps={{
            numberOfMonths: 1,
            showOutsideDays: true,
            todayButton: 'Today',
          }}
          onChange={onDayChange}
          value={metadata.authoredAt || new Date()}
        />
      </Grid>
    </Boxed>
  );
};

export default MetadataBar;
