import 'mapbox-gl/dist/mapbox-gl.css';
import './assets/css/index.css';
import './assets/css/app.css';
import './assets/fonts/fontello/css/animation.css';
import './assets/fonts/fontello/css/map-composer.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import App from './App';
import { Theme } from './components/styled-components';
import store from './redux';
import * as serviceWorker from './serviceWorker';

console.log('Revision:  [', process.env.REACT_APP_REVISION, ']');

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ReduxProvider store={store}>
        <ThemeProvider theme={Theme}>
          <App />
        </ThemeProvider>
      </ReduxProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
