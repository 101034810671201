import { darken, lighten, transparentize } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { helpers } from '../../utils';
import { Label } from './Typography';

export const InputWrapper = styled.div`
  background: ${(props) => props.theme.PrimaryWhite};
  display: inline-block;
  font-family: ${(props) => props.theme.PrimaryFont};
  font-size: ${(props) => props.theme.FontSize_standard};
  position: relative;
  transition: ${(props) => props.theme.PrimaryTransition};
  width: 100%;

  ${(props) =>
    props.forminput &&
    css`
      margin-bottom: 15px;
      margin-top: 10px;
    `}

  &::after {
    display: none;
  }

  ${(props) =>
    props.required &&
    css`
      &::after {
        color: ${(props) => props.theme.PrimaryRed};
        content: '\\f0a3'; // mp-certificate
        display: block;
        font-family: ${(props) => props.theme.IconFont};
        font-size: ${(props) => props.theme.FontSize_s};
        height: 10px;
        left: -5px;
        position: absolute;
        top: -5px;
        width: 10px;
        z-index: 2;
      }
    `}

  & input {
    background: none;
    border-radius: ${(props) => props.theme.PrimaryRadius};
    border: 1px solid ${(props) => lighten(0.6, props.theme.PrimaryFontColor)};
    box-sizing: border-box;
    display: block;
    font-size: ${(props) => props.theme.FontSize_standard};
    line-height: 22px;
    max-height: 44px;
    padding: 10px;
    position: relative;
    transition: ${(props) => props.theme.PrimaryTransition};
    width: 100%;
    z-index: 2;

    ${(props) =>
      (props.type === 'number' || props.type === 'phone') &&
      css`
        -moz-appearance: textfield;
        appearance: textfield;
        margin: 0;

        &::-webkit-inner-spin-button {
          display: none;
          opacity: 0;
        }
      `}

    &::-webkit-file-upload-button {
      visibility: hidden;
    }

    ${(props) =>
      props.type === 'file' &&
      css`
        &::before {
          -webkit-user-select: none;
          color: ${(props) => props.theme.PrimaryGrey};
          content: 'Select logo';
          cursor: pointer;
          font-family: ${(props) => props.theme.PrimaryFont};
          font-size: ${(props) => props.theme.FontSize_s};
          outline: none;
          padding: 3px 0;
          text-transform: uppercase;
        }
      `}

    &:disabled {
      background: ${(props) => lighten(0.7, props.theme.PrimaryFontColor)};
      border: 1px solid ${(props) => lighten(0.67, props.theme.PrimaryFontColor)};
      color: ${(props) => lighten(0.5, props.theme.PrimaryFontColor)};
    }

    &:disabled::placeholder {
      color: ${(props) => lighten(0.5, props.theme.PrimaryFontColor)};
    }

    &:focus {
      border: 1px solid ${(props) => props.theme.PrimaryColor};
      box-shadow: none;
      outline: none;
    }

    &:focus:hover {
      border-width: 1px;
      box-shadow: none;
    }

    ${(props) =>
      props.error &&
      css`
        border: 1px solid ${(props) => lighten(0.15, props.theme.PrimaryRed)};
      `}
  }

  & em {
    bottom: -15px;
    display: inline-block;
    font-size: ${(props) => props.theme.FontSize_xs};
    font-style: italic;
    position: absolute;
    right: 0;
  }

  &::before {
    font-family: ${(props) => props.theme.IconFont};
    ${(props) =>
      props.type === 'search' &&
      css`
        content: '\\e81c'; // mp-search
      `}
    ${(props) =>
      props.type === 'file' &&
      css`
        content: '\\e85a'; // mp-upload-cloud
      `}
    ${(props) =>
      props.type === 'phone' &&
      css`
        content: '\\f034'; // mp-mobile
      `}
    ${(props) =>
      props.type === 'password' &&
      css`
        content: '\\e85f'; // mp-lock-open-filled
      `}
    ${(props) =>
      props.type === 'number' &&
      css`
        content: '\\e85e'; // mp-sort-numeric
      `}
    ${(props) =>
      props.type === 'text' &&
      css`
        content: '\\e85c'; // mp-sort-alphabet
      `}
    ${(props) =>
      props.type === 'email' &&
      css`
        content: '\\e85d'; // mp-mail
      `}
    ${(props) =>
      props.type === 'location' &&
      css`
        content: '\\e842'; // mp-location-1
      `}

    align-items: center;
    color: ${(props) => lighten(0.3, props.theme.PrimaryFontColor)};
    display: flex;
    font-size: ${(props) => props.theme.FontSize_m};
    height: 44px;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 36px;
    z-index: 1;
  }

  & span {
    color: ${(props) => transparentize(0.3, props.theme.PrimaryFontColor)};
    font-size: ${(props) => props.theme.FontSize_xs};
    font-weight: 600;
    left: 2px;
    letter-spacing: 0.1em;
    position: absolute;
    top: -16px;
  }

  & .eha__control {
    border: 1px solid ${(props) => lighten(0.6, props.theme.PrimaryFontColor)};
    border-radius: ${(props) => props.theme.PrimaryRadius};
    font-size: ${(props) => props.theme.FontSize_standard};
    height: 44px;

    ${(props) =>
      props.error &&
      css`
        border: 1px solid ${(props) => lighten(0.15, props.theme.PrimaryFontColor)};
      `}

    & .eha__input input {
      height: 0;
    }
  }

  & .eha__indicators {
    & span {
      display: none;
    }
  }

  & .eha__menu {
    background: ${(props) => darken(0.1, props.theme.PrimaryGreyDark)};
    border: 0;
    border-radius: ${(props) => props.theme.PrimaryRadius};
    color: ${(props) => props.theme.PrimaryWhite};
    overflow: hidden;
    z-index: 500;

    & .eha__menu-list {
      margin: 0;
      padding: 0;

      & .eha__option {
        &:hover {
          background: ${(props) => props.theme.PrimaryGreyDark};
        }

        &.eha__option--is-focused {
          background: ${(props) => transparentize(0.6, props.theme.PrimaryGreyDark)};
        }

        &.eha__option--is-selected {
          background: ${(props) => darken(0.2, props.theme.PrimaryColor)};
        }
      }
    }
  }
`;

export const Input = (props) => {
  const keys = ['error', 'forminput', 'inline', 'label'];
  return (
    <InputWrapper {...props}>
      {props.label && <Label>{props.label}</Label>}
      <input
        {...helpers.omit(props, keys)}
        type={props.type === 'phone' ? 'number' : props.type}
      />
      {props.error && <em>{props.error}</em>}
    </InputWrapper>
  );
};

Input.defaultProps = {
  type: 'text',
};

Input.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
  forminput: PropTypes.bool,
  inline: PropTypes.bool,
  label: PropTypes.string,
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  style: PropTypes.object,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
