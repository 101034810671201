import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../components/Header';
import Loader from '../components/Loader';
import LocalizationModal from '../components/LocalizationBar/LocalizationModal';
import { Boxed, Tabs } from '../components/styled-components';
import { loadDataLayerFeatures } from '../redux/actions/layers';
import { getDocument } from '../redux/actions/mapDocument';
import { getMapTemplate } from '../redux/actions/mapTemplate';
import { DefaultRootState } from '../types';
import ExplorerBarStrip from './explorer/ExplorerBarStrip';
import ExplorerView from './explorer/ExplorerView';
import PreviewBarStrip from './preview/PreviewBarStrip';
import PreviewView from './preview/PreviewView';

const Composer = (props) => {
  const dispatch = useDispatch();
  const isFirstRun = useRef(true);

  const {
    loader: {
      mapTemplate: loadingMapTemplate,
      dataLayerFeatures: loadingDataLayerFeatures,
    },
    dataLayer: { dataLayerFeatures },
    mapDocument: { current },
    location: {
      currentLocation: { level: locationLevel, value: locationValue },
    },
  } = useSelector((state: DefaultRootState) => state);

  const {
    match: {
      params: { mapDocumentId },
    },
  } = props;

  useEffect(() => {
    if (isFirstRun.current) {
      if (mapDocumentId) {
        dispatch(getDocument(mapDocumentId));
      } else {
        dispatch(getMapTemplate());
      }
      isFirstRun.current = false;
      return;
    }

    if (current.id && Object.keys(dataLayerFeatures).length === 0) {
      const {
        mapTemplate: { id: mapTemplateId },
        dataLayerIds,
      } = current;
      dispatch(
        loadDataLayerFeatures(
          mapTemplateId,
          locationLevel,
          locationValue,
          dataLayerIds,
        ),
      );
    }
  }, [
    current,
    dataLayerFeatures,
    dispatch,
    locationLevel,
    locationValue,
    mapDocumentId,
  ]);

  return (
    <div className="App">
      <Header />

      <Tabs bg>
        <Boxed
          label="Map Exploration"
          sublabel="Configure Map Attributes"
          icon="mp-map-explore"
        >
          <ExplorerBarStrip />
          {loadingMapTemplate || loadingDataLayerFeatures ? <Loader /> : ''}
          <ExplorerView />
        </Boxed>

        <Boxed
          label="Map Preview"
          sublabel="Show preview &amp; Table Content"
          icon="mp-map-preview"
        >
          <PreviewBarStrip />
          <PreviewView />
        </Boxed>
      </Tabs>

      <LocalizationModal />
    </div>
  );
};

export default Composer;
