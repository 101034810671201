import bbox from '@turf/bbox';
import { feature } from '@turf/helpers';

import {
  CLEAR_LOCATIONS,
  GET_LOCATIONS_SUCCESS,
  GET_MAP_DOCUMENT_SUCCESS,
  LOCALIZE_MAP,
  RESET_MAP_DOCUMENT,
  TOGGLE_LOCATION_MODAL,
} from '../types';

const NIGERIA = 'Nigeria';

const INITIAL_STATE = {
  showModal: true,
  locations: [],
  currentLocation: {
    id: 0,
    name: NIGERIA,
    parents: NIGERIA,
    centroid: [5.760326, 8.580626],
    boundingBox: [
      [2.6926125380000485, 4.271484438000073],
      [14.677967754000065, 13.885713987000031],
    ],
  },
  containerStyle: { height: '100%', width: '100%' },
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case TOGGLE_LOCATION_MODAL: {
      const { showModal } = action;
      return {
        ...state,
        showModal,
      };
    }
    case CLEAR_LOCATIONS:
      return {
        ...state,
        locations: [],
      };
    case LOCALIZE_MAP: {
      const currentLocation = (({
        name,
        value,
        level,
        fullName,
        parents,
        centroid,
        geom: boundary,
        boundingBox,
      }) => ({
        name,
        value,
        level,
        fullName,
        parents,
        centroid,
        boundary,
        boundingBox,
      }))(payload);
      return {
        ...state,
        currentLocation,
      };
    }
    case GET_LOCATIONS_SUCCESS: {
      const locations = payload;
      return {
        ...state,
        locations,
      };
    }
    case GET_MAP_DOCUMENT_SUCCESS: {
      const {
        localization: { name, level, code: value, fullName, boundary },
      } = payload;
      const box = bbox(feature(boundary));
      const boundingBox = [
        [box[0], box[1]],
        [box[2], box[3]],
      ];
      return {
        ...state,
        currentLocation: {
          ...state.currentLocation,
          boundingBox,
          boundary,
          name,
          level,
          value,
          fullName,
        },
      };
    }
    case RESET_MAP_DOCUMENT: {
      return {
        ...state,
        currentLocation: INITIAL_STATE.currentLocation,
      };
    }
    default:
      return state;
  }
};
