import bbox from '@turf/bbox';
import { feature } from '@turf/helpers';

import { urls } from '../../utils';
import {
  CLEAR_LOCATIONS,
  GET_LOCATIONS_FAILURE,
  GET_LOCATIONS_REQUEST,
  GET_LOCATIONS_SUCCESS,
  LOCALIZE_MAP,
  RESET_SELECTIONS,
  TOGGLE_LOCATION_MODAL,
} from '../types';

export const clearLocations = () => (dispatch) => {
  dispatch({
    type: CLEAR_LOCATIONS,
  });
};

export const toggleModal =
  (showModal = false) =>
  (dispatch) => {
    dispatch({
      showModal,
      type: TOGGLE_LOCATION_MODAL,
    });
  };

export const getLocations = (textInput) => (dispatch) => {
  dispatch({
    types: [GET_LOCATIONS_REQUEST, GET_LOCATIONS_SUCCESS, GET_LOCATIONS_FAILURE],
    promise: (client: any) => client.get(`${urls.LOCATIONS}/search?query=${textInput}`),
  });
};

export const localizeMap = (location) => (dispatch) => {
  const box = bbox(feature(location.geom));
  const boundingBox = [
    [box[0], box[1]],
    [box[2], box[3]],
  ];

  dispatch({
    type: LOCALIZE_MAP,
    payload: {
      ...location,
      fullName: location.parents
        ? `${location.parents.replace('*', '>')} > ${location.name}`
        : location.name,
      boundingBox,
    },
  });
  dispatch({ type: RESET_SELECTIONS });
};
