import { CheckedFeatureCollection, DictionaryOf, MapBoxStyle } from '../../types';
import { helpers } from '../../utils';
import {
  GET_DATA_LAYERS_SUCCESS,
  GET_MAP_DOCUMENT_SUCCESS,
  GET_MAP_TEMPLATE_SUCCESS,
  RESET_MAP_DOCUMENT,
  TOGGLE_DATA_LAYER,
  TOGGLE_DATA_LAYER_LABEL,
} from '../types';

type initState = {
  dataLayerFeatures: DictionaryOf<CheckedFeatureCollection>;
  dataLayerStyles: DictionaryOf<Array<MapBoxStyle>>;
};

const INITIAL_STATE: initState = {
  dataLayerFeatures: {},
  dataLayerStyles: {},
};

export default (state = INITIAL_STATE, action) => {
  const { data, payload, type } = action;
  switch (type) {
    case TOGGLE_DATA_LAYER: {
      const { dataLayerId } = data;
      const { dataLayerFeatures } = state;
      const dataLayerFeature = dataLayerFeatures[dataLayerId];
      if (dataLayerFeature) {
        return {
          ...state,
          dataLayerFeatures: {
            ...dataLayerFeatures,
            [dataLayerId]: {
              ...dataLayerFeature,
              checked: !dataLayerFeature.checked,
            },
          },
        };
      } else {
        return state;
      }
    }
    case GET_DATA_LAYERS_SUCCESS: {
      const { dataLayerIds } = action;
      const dataLayerFeatures = {};
      const { dataLayerFeatures: currentDataLayerFeatures } = { ...state };
      payload.forEach((feature) => {
        const { category: dataLayerId } = feature;
        const documentLayerChecked = dataLayerIds.includes(dataLayerId);
        const { checked } = currentDataLayerFeatures[dataLayerId] || {};
        const hasFeatures = (feature.features || {}).features.length > 0;
        dataLayerFeatures[dataLayerId] = feature.features;
        dataLayerFeatures[dataLayerId].checked =
          hasFeatures && (checked || documentLayerChecked);
        dataLayerFeatures[dataLayerId].showLabel = true;
      });
      return {
        ...state,
        loadingFeatures: false,
        dataLayerFeatures,
      };
    }
    case GET_MAP_TEMPLATE_SUCCESS: {
      const dataLayerStyles = { ...state.dataLayerStyles };
      payload.dataLayers.forEach(({ id, layerStyle }) => {
        dataLayerStyles[id] = helpers.configureStyles(layerStyle);
      });
      return {
        ...state,
        dataLayerStyles,
      };
    }
    case GET_MAP_DOCUMENT_SUCCESS: {
      const dataLayerStyles = { ...state.dataLayerStyles };
      payload.mapTemplate.dataLayers.forEach(({ id, layerStyle }) => {
        dataLayerStyles[id] = helpers.configureStyles(layerStyle);
      });
      return {
        ...state,
        dataLayerStyles,
      };
    }
    case TOGGLE_DATA_LAYER_LABEL: {
      const { dataLayerId } = data;
      const { dataLayerFeatures } = state;
      const dataLayerFeature = dataLayerFeatures[dataLayerId];
      const dataLayerStyles = helpers.toggleVisibility(
        state.dataLayerStyles,
        dataLayerId,
        'text',
        !dataLayerFeature.showLabel,
      );
      return {
        ...state,
        dataLayerStyles,
        dataLayerFeatures: {
          ...dataLayerFeatures,
          [dataLayerId]: {
            ...dataLayerFeature,
            showLabel: !dataLayerFeature.showLabel,
          },
        },
      };
    }
    case RESET_MAP_DOCUMENT: {
      const dataLayerFeatures = { ...state.dataLayerFeatures };
      Object.keys(dataLayerFeatures).forEach((key) => {
        dataLayerFeatures[key].checked = false;
      });
      return {
        ...state,
        dataLayerFeatures,
      };
    }
    default:
      return state;
  }
};
