import React from 'react';
import { NavLink as Link } from 'react-router-dom';

import Ehalogo from '../assets/images/eha-logo-white.png';
import Applogo from '../assets/images/mapping-application.svg';
import { Boxed, TopBar } from './styled-components';

const Header = () => {
  return (
    <div>
      <TopBar className="app-header mb-1" sticky>
        <div className="app-logo">
          <Link to="/">
            <img src={Applogo} alt="Mapping" className="logo-img mr-4 ml-2 svg-logo" />
          </Link>
        </div>

        <Boxed width="100%" />

        <div className="eha-logo">
          <img src={Ehalogo} alt="eHealth" className="logo-img" />
        </div>
      </TopBar>
    </div>
  );
};

export default Header;
