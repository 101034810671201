import React from 'react';

import { PaleButton } from '../styled-components';

const DATA_PORTAL_URL =
  process.env.DATA_PORTAL_URL || 'https://data.ehealthafrica.org/';

export const DataPortalLink = () => (
  <a
    className="edp-link"
    href={DATA_PORTAL_URL}
    target="_blank"
    rel="noopener noreferrer"
  >
    <PaleButton className="edp-button"> Visit eHA Data Portal </PaleButton>
  </a>
);
