import { transparentize } from 'polished';
import styled from 'styled-components';

export const Pagination = styled.div`
  clear: both;
  display: block;
  list-style: none;
  margin: 0;
  padding: 20px 0;
  text-align: right;

  & li {
    display: inline-block;
    list-style: none;

    & a {
      background: ${(props) => props.theme.PrimaryWhite};
      border: 1px solid
        ${(props) =>
          props.color
            ? transparentize(0.3, props.color)
            : transparentize(0.2, props.theme.PrimaryColor)};
      border-radius: 2px;
      color: ${(props) => props.theme.PrimaryColor};
      cursor: pointer;
      display: inline-block;
      font-size: ${(props) => props.theme.FontSize_standard};
      margin: 2px;
      padding: 5px 9px;
      text-align: center;
      text-decoration: none;
    }

    & a.elli {
      background: none;
      border: 0;
    }

    & a.elli:hover {
      background: none;
      border: 0;
    }

    & a:hover {
      background: ${(props) =>
        props.color
          ? transparentize(0.3, props.color)
          : transparentize(0.2, props.theme.PrimaryColor)};
      border: 1px solid
        ${(props) =>
          props.color
            ? transparentize(0.3, props.color)
            : transparentize(0.2, props.theme.PrimaryColor)};
      color: ${(props) => props.theme.PrimaryWhite};
    }

    &.active {
      & a {
        background: ${(props) => props.theme.PrimaryColor};
        border: 1px solid ${(props) => props.theme.PrimaryColor};
        color: ${(props) => props.theme.PrimaryWhite};

        &:active {
          background: ${(props) => props.theme.PrimaryColor};
          border: 1px solid ${(props) => props.theme.PrimaryColor};
          color: ${(props) => props.theme.PrimaryWhite};
        }
      }
    }
  }
`;
