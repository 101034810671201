import React from 'react';
import Select, { Props } from 'react-select';

import { InputWrapper } from './Inputs';
import { Label } from './Typography';

type ExtendedProps = Props & {
  error: string;
  label: string;
  forminput: boolean;
};

export const SimpleSelect = (props: ExtendedProps) => {
  return (
    <InputWrapper {...props}>
      {props.label && <Label>{props.label}</Label>}
      <Select {...props} classNamePrefix="eha" />
      {props.error && <em>{props.error}</em>}
    </InputWrapper>
  );
};
