import React from 'react';

import { Boxed, H5, Theme } from '../styled-components';
import DataLegendPane from './DataLegendPane';
import SystemLegendPane from './SystemLegendPane';

const LegendSidebar = () => {
  return (
    <Boxed outline className="side-bar left">
      <Boxed
        outline
        pad="1.4rem 2rem"
        background={Theme.PrimaryBackground}
        className="side-bar-header"
      >
        <H5>Legends </H5>
      </Boxed>
      <SystemLegendPane />
      <DataLegendPane />
    </Boxed>
  );
};

export default LegendSidebar;
